import React, { useReducer, useState, useEffect } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar,
  Box, Grid, Select, MenuItem, FormControl, InputLabel,
  FormHelperText
} from '@mui/material';
import { MdOutlineAddchart, MdSearch, } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import { Alert } from '@mui/lab';
import styles from "../dailyexpensive/dailyexpensive.module.css";
import { CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { Delete, ModeEditOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

const apiurl = process.env.REACT_APP_API_URL;
const initialState = {
  formValues: {
    storeId: '',
    storeName: '',
    modeOfPayment: '',
    amount: '',
    reason: '',
  },
  errors: {},
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUES':
      return {
        ...state,
        formValues: { ...state.formValues, ...action.payload },
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: { ...state.errors, ...action.payload },
      };
    case 'RESET_FORM':
      return {
        ...state,
        formValues: initialState.formValues,
        errors: {},
      };
    default:
      return state;
  }
};

const DailyExpensives = () => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [expensiveId, setexpensiveId] = useState(null);
  const [ExpensiveList, setExpensiveList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [stores, setStores] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [totalPages, setTotalPages] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  //=================================================user api==============================================================
  const [userRole, setUserRole] = useState('');
  const [userStore, setUserStore] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${apiurl}user/loggeduser`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUserRole(data.user.roleName.toLowerCase());
        if (data.user.roleName.toLowerCase() === 'store') {
          setUserStore({
            id: data.user.storeId,
            storeName: data.user.storeName
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);
  //========================================================loading===============================================================
  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <CircularProgress />
    </div>
  );

  const formatIndianCurrency = (x) => {
    if (x === undefined || x === null) return '0.00';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };

  //====================================================================================

  const tableCellStyle = {
    height: '25px',
    padding: '4px',  // Ensure no extra padding affects height
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333'

  };

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };
  const [query, setQuery] = useState('');

  const fetchExpense = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    try {
      const baseUrl = `${apiurl}expense`;
      const params = new URLSearchParams({
        page: currentPage.toString(),
        limit: rowsPerPage.toString()
      });

      if (fromDate) params.append('fromDate', fromDate);
      if (toDate) params.append('toDate', toDate);
      if (query) params.append('query', query);
      if (userRole === 'store' && userStore) {
        params.append('storeId', userStore.id);
      }

      const url = `${baseUrl}?${params.toString()}`;

      console.log('Fetching URL:', url);

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch expenses');
      }
      const data = await response.json();

      if (data.status === "success") {
        setExpensiveList(data.expenses);
        setTotalPages(Math.ceil(data.totalExpenses / rowsPerPage));
      } else {
        console.error('API response indicates failure:', data);
        setExpensiveList([]);
        setTotalPages(0);
      }

    } catch (error) {
      console.error('Error fetching expenses:', error);
      setExpensiveList([]);
      setTotalPages(0);
    } finally {
      setIsLoading(false);
    }
  };



  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    fetchExpense();
    // setQuery('');

  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchExpense();
  }, [currentPage, rowsPerPage,]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (['amount'].includes(name)) {
      const isNumeric = /^\d*\.?\d*$/.test(value);
      if (!isNumeric) return;
    }

    if (name === 'storeId') {
      if (userRole === 'store') {
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: {
            storeId: userStore.id,
            storeName: userStore.storeName
          }
        });
      } else {
        const selectedStore = stores.find(store => store.id === value);
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: {
            storeId: value,
            storeName: selectedStore ? selectedStore.storeName : ''
          }
        });
      }
    } else {
      dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: value } });
    }
    dispatch({ type: 'SET_ERRORS', payload: { [name]: '' } });
  };


  const validateForm = () => {
    const { storeId, amount, reason, modeOfPayment, } = state.formValues;
    const newErrors = {};
    console.log(state.formValues)
    if (!modeOfPayment) newErrors.modeOfPayment = 'Mode of Payment is required';
    if (!amount) newErrors.amount = 'Amount is required';
    if (!reason) newErrors.reason = 'Reason is required';
    // Only validate storeId for admin and staff roles
    if (userRole !== 'store' && !storeId) {
      newErrors.storeId = 'Store Name is required';
    }

    console.log('Validation errors:', newErrors);

    dispatch({ type: 'SET_ERRORS', payload: newErrors });
    return Object.keys(newErrors).length === 0;
  };


  const handleAddorUpdateExpense = async (e) => {
    const token = localStorage.getItem('token');

    setIsLoading(true);

    e.preventDefault();

    if (!validateForm()) {
      setErrorSnackbarOpen(true);
      setIsLoading(false);  // Set loading to false if validation fails

      return;
    }


    const newExpense = {

      storeId: userRole === 'store' ? userStore.id : parseInt(state.formValues.storeId),
      modeOfPayment: state.formValues.modeOfPayment,
      amount: parseFloat(state.formValues.amount),
      reason: state.formValues.reason,

    };

    try {
      let response; // Declare response variable
      if (editIndex !== null) {
        response = await fetch(`${apiurl}expense/${expensiveId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },

          body: JSON.stringify(newExpense),
        });
      } else {
        response = await fetch(`${apiurl}expense`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },

          body: JSON.stringify(newExpense),
        });
      }
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to add/update expense: ${errorData.message || response.statusText}`);
      }

      const newExpenseData = await response.json();
      // console.log('expense added/updated successfully', newExpenseData);

      // Add the new quotation to the list
      setExpensiveList(prevList => {
        if (editIndex !== null) {
          // Update existing expense
          showAlert('DailyExpense Data updated successfully', 'success');

          return prevList.map((expense, index) =>
            index === editIndex ? { ...expense, ...newExpenseData } : expense
          );
        } else {
          // Add new expense
          showAlert('DailyExpense Data added successfully', 'success');

          return [newExpenseData, ...prevList];
        }
      });


      dispatch({ type: 'RESET_FORM' });

      setShowForm(false);
      setEditIndex(null);
      setexpensiveId(null);

      fetchExpense();

    } catch (error) {
      console.error('Error adding/updating details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDeleteOpen = (expensiveId) => {
    setDeleteIndex(expensiveId);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setDeleteIndex(null);
  };

  const handleDeleteExpense = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      if (!deleteIndex) {
        throw new Error('Invalid product selected for deletion');
      }

      const response = await fetch(`${apiurl}expense/${deleteIndex}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },

      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete expense');
      }

      console.log('Expense deleted successfully');

      setExpensiveList((prevExpensiveList) => {
        const updatedList = prevExpensiveList.filter((expense) => expense._id !== deleteIndex);

        if (updatedList.length === 0 && currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }

        return updatedList;
      });
      // Fetch the updated product list
      fetchExpense();

      showAlert('Expense deleted successfully', 'success');

    } catch (error) {
      console.error('Error deleting Expense:', error);
      showAlert(`Failed to delete Expense: ${error.message}`, 'error');
    } finally {
      setConfirmDeleteOpen(false);
      setDeleteIndex(null);
      setIsLoading(false);
    }
  };

  const handleEditExpense = (_id, index) => {
    setexpensiveId(_id);
    const actualIndex = (currentPage - 1) * itemsPerPage + index;
    const expenseToEdit = ExpensiveList.find(product => product._id === _id);

    if (!expenseToEdit) {
      console.error('Expense not found:', _id, index, actualIndex);
      showAlert('Error: Expense not found', 'error');
      return;
    }

    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        storeId: expenseToEdit.storeId || '',
        modeOfPayment: expenseToEdit.modeOfPayment || '',
        amount: expenseToEdit.amount || '',
        reason: expenseToEdit.reason || '',
      }
    });
    setShowForm(true);
    setEditIndex(ExpensiveList.findIndex(expense => expense._id === expensiveId));
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };



  //==================================store define api===============================================
  const fetchstores = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiurl}store`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch stores');
      }
      const data = await response.json();
      console.log('Fetched store data:', data);

      if (data.status === 'success' && Array.isArray(data.stores)) {
        const storeList = data.stores.map(store => ({
          id: store.storeId, // या store.id, जो भी आपके API में उपयोग हो रहा है
          storeName: store.storeName,
          city: store.city
        }));
        setStores(storeList);
      } else {
        console.error('Unexpected data format:', data);
        setStores([]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setStores([]);
    }
  };
  useEffect(() => {
    fetchstores();
  }, []);

  useEffect(() => {
    console.log('Updated stores:', stores);
  }, [stores]);


  return (
    <div className={styles.container}>
      <div className={styles.maincontainer}>
        {isLoading && <LoadingOverlay />}
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              name="fromDate"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true // This ensures the label stays above the input value
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="toDate"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true // This ensures the label stays above the input value
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="query"
              label="Search by reason"
              value={query}
              onChange={handleQueryChange}
              InputProps={{
                className: styles.invoiceinput,
              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true
              }} />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdSearch />}
              onClick={handleSearch}>
              Search
            </Button>
          </Grid>
          <Grid item >
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdOutlineAddchart />}
              onClick={() => setShowForm(true)}>
              Add Expense
            </Button>
          </Grid>
        </Grid>

        <Dialog
          open={showForm}
          onClose={() => { }}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            sx: {
              margin: 'auto',
              mt: 0, // Set the margin-top to 0
              width:'100%'
            },
          }}
          sx={{
            display: 'flex',
            alignItems: 'flex-start', // Align dialog to the top
            justifyContent: 'center',
            width:"100%"
          }}
        >
          <DialogTitle>{editIndex !== null ? 'Edit Expenses' : 'Add Expenses'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editIndex !== null ? 'Edit the details of the Expenses.' : 'Fill in the details of the new Expenses.'}
            </DialogContentText>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleAddorUpdateExpense}
              sx={{ mt: 2, }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl
                    fullWidth
                    error={!!state.errors.storeId}
                    disabled={userRole === 'store'}
                    sx={{
                      '& .MuiInputLabel-root': {
                        top: '0px',
                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                      },
                      '& .MuiSelect-root': {
                        minHeight: 'auto', // Adjust if needed for height consistency
                      },
                    }}
                  >
                    <InputLabel>
                      Store <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <Select
                      name="storeId"
                      value={userRole === 'store' ? userStore?.id : state.formValues.storeId}
                      onChange={handleInputChange}
                      label="Store"
                      required={userRole !== 'store'}
                      className={styles.textField}
                    >
                      {userRole === 'store' ? (
                        <MenuItem value={userStore?.id}>{userStore?.storeName}</MenuItem>
                      ) : (
                        [
                          <MenuItem key="none" value=""><em>None</em></MenuItem>,
                          ...stores.map((store) => (
                            <MenuItem key={store.id} value={store.id}>
                              {store.storeName}
                            </MenuItem>
                          ))
                        ]
                      )}
                    </Select>
                    {state.errors.storeId && (
                      <FormHelperText>{state.errors.storeId}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth error={!!state.errors.modeOfPayment}>
                    <InputLabel sx={{ top: '0px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>
                      Mode Of Payment <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <Select
                      name="modeOfPayment"
                      label="Mode Of Payment"
                      value={state.formValues.modeOfPayment}
                      className={styles.textField}
                      onChange={handleInputChange}
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      <MenuItem value="CASH">Cash</MenuItem>
                      <MenuItem value="UPI">UPI</MenuItem>
                    </Select>
                    {state.errors.modeOfPayment && (
                      <FormHelperText>{state.errors.modeOfPayment}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="amount"
                    label="Amount"
                    value={formatIndianCurrency(state.formValues.amount)}
                    onChange={(e) => {
                      const value = e.target.value.replace(/,/g, ''); // Remove commas for processing

                      // Allow only numbers up to 11 digits and optional decimals
                      if (value === '' || /^[0-9]{0,11}(\.\d{0,2})?$/.test(value)) {
                        handleInputChange({
                          target: {
                            name: 'amount',
                            value: value,
                          },
                        });
                      }
                    }}
                    className={styles.textField}
                    error={!!state.errors.amount}
                    helperText={state.errors.amount}
                    required
                    fullWidth
                    sx={{
                      '& .MuiInputLabel-root': {
                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                        // Hide the default asterisk and apply custom styles
                        '& .MuiFormLabel-asterisk': {
                          color: 'red', // Customize the color of the asterisk
                        },
                      },
                      '& .MuiInputBase-root': {
                        color: '#000', // Text color
                      },
                    }}
                  />

                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="reason"
                    label="Reason"
                    value={state.formValues.reason}
                    onChange={handleInputChange}
                    className={styles.textField}
                    error={!!state.errors.reason}
                    helperText={state.errors.reason}
                    required
                    fullWidth
                    sx={{
                      '& .MuiInputLabel-root': {
                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                        // Hide the default asterisk and apply custom styles
                        '& .MuiFormLabel-asterisk': {
                          color: 'red', // Customize the color of the asterisk
                        },
                      },
                      '& .MuiInputBase-root': {
                        color: '#000', // Text color
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <DialogActions sx={{ mt: 2, justifyContent: "flex-start" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#009999',
                    color: '#fff',
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#01dcb8',
                    },
                  }}
                  type="submit"
                  startIcon={<AddIcon />}

                >
                  {editIndex !== null ? 'Update Expense' : 'Add Expense'}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#e32e2e',
                    color:"fff",
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#ff0000',
                    },}}

                  onClick={() => {
                    dispatch({ type: 'RESET_FORM' });

                    setShowForm(false);
                    setEditIndex(null);
                  }}
                  startIcon={<CloseIcon />}

                >
                  Cancel
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
          <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
          <Select
            labelId="rows-per-page-label"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            label="Rows per page"
          >
            {[10, 25, 50, 100].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className={styles.dailytablecontainer}>
          <TableContainer component={Paper}>
            <Table className={styles.storetable}>
              <TableHead>
                <TableRow>

                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                  {userRole !== 'store' && (
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Store Name</TableCell>
                  )}
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Mode of Payment</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Amount</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Reason</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Date</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {ExpensiveList?.map((expense, index) => (
                  <TableRow
                    key={expense._id}
                    style={{
                      backgroundColor: !expense.isActive
                        ? '#ffcccc'  // Light red color for inactive rows
                        : index % 2 === 0
                          ? 'white'
                          : '#f0f0f0'
                    }}
                  >
                    <TableCell style={tableCellStyle}>{(index + 1 + (currentPage - 1) * itemsPerPage)
                      .toString().padStart(4, '0')}</TableCell>
                    {userRole !== 'store' && (
                      <TableCell style={tableCellStyle}>{expense.storeName}</TableCell>
                    )}
                    <TableCell style={tableCellStyle}>{expense.modeOfPayment}</TableCell>
                    <TableCell style={tableCellStyle}>{expense.amount}</TableCell>
                    <TableCell style={tableCellStyle}>{expense.reason}</TableCell>
                    <TableCell style={tableCellStyle}>{expense.createdAt}</TableCell>
                    <TableCell style={tableCellStyle}>
                      <IconButton onClick={() => handleEditExpense(ExpensiveList[index]._id, index + (currentPage - 1) * itemsPerPage)} >
                        <ModeEditOutline className={styles.editIcon} />
                      </IconButton>
                      <IconButton onClick={() => handleConfirmDeleteOpen(expense._id)} >
                        <Delete className={styles.editDelete} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>


            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#009999',
                  border: '1px solid #ddd',
                  margin: '0 4px',
                  borderRadius: '4px',
                },
                '& .Mui-selected': {
                  backgroundColor: '#009999',
                  color: 'white',
                },
              }}
            />
          </Box>
        </div>

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            Please fill in all fields correctly.
          </Alert>
        </Snackbar>

        <Dialog
          open={confirmDeleteOpen}
          onClose={handleConfirmDeleteClose}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this expense list?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleConfirmDeleteClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDeleteExpense} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* ====================================================alert======================================================= */}

        {alertInfo.open && (
          <Alert
            severity={alertInfo.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertInfo({ ...alertInfo, open: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
            sx={{
              position: 'fixed',
              top: 16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
            }}
          >
            {alertInfo.message}
          </Alert>
        )}
      </div>
    </div>
  );
};
export default DailyExpensives;
