import React, { useState } from "react";
import styles from "../forgotpassword/forgot.module.css";
import { Button, Snackbar, Alert } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import FlCopy from "../assets/fl copy.png";

const apiurl = process.env.REACT_APP_API_URL;

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");
        setLoading(true);

        try {
            const response = await fetch(`${apiurl}user/send-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email })
            });

            const data = await response.json();

            if (response.ok) {
                setMessage("Check your email, you will receive a New password via email.");
                setOpenSnackbar(true);
            } else {
                setError(data.error || "An error occurred. Please try again.");
                setOpenSnackbar(true);
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <img src={FlCopy} alt="" className={styles.logo} />
                <h1 className={styles.text}>Forgot Password</h1>
                <h3 className={styles.subheading}>Please enter your email address.</h3>
                <div className={styles.inputbox}>
                    <EmailIcon className={styles.icon} />
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                <Button 
                sx={{backgroundColor: 'rgb(250, 45, 45)',
                color: '#fff', 
                width:"auto",
                borderRadius:"20px",
                alignSelf:"center",
                '&:hover': {
                  backgroundColor: ' rgb(246, 64, 64)',
                },
                }} 
                type="submit" disabled={loading}>
                    {loading ? <ClipLoader size={20} color={"#ffffff"} /> : "Reset Password"}
                </Button>

                <Link to="/" className={styles.loginbtn}> Back to Login </Link>
             
            </form>

            {/* Snackbar for success and error messages */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set to top and center
            >
                {message ? (
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity="success"
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center', // Center the content
                            justifyContent: 'center',
                        }}
                    >
                        {message}
                    </Alert>
                ) : (
                    error && (
                        <Alert
                            onClose={handleCloseSnackbar}
                            severity="error"
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center', // Center the content
                                justifyContent: 'center',
                            }}
                        >
                            {error}
                        </Alert>
                    )
                )}
            </Snackbar>

        </div>
    );
};

export default ForgotPassword;
