
import React, { useReducer, useState, useEffect } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar,
  Box, Grid, MenuItem, Divider, Select, InputLabel, FormControl, InputAdornment, styled
} from '@mui/material';
import { MdSearch, MdPrint } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import ClearIcon from '@mui/icons-material/Clear';
import fl from '../../assets/fl copy.png';
import { Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DialogProduct from '../../DIALOG/dialogproduct';
import styles from "../purchaseFormat/purchaseFormat.module.css";
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { Delete, Edit, Preview } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { showDeleteConfirmation } from '../../DIALOG/sweetAlert';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const initialState = {
  formValues: {
    orderNumber: '',
    // storeId: '',  // Add this line
    storeName: '',
    vendorName: '',
    vendorContact: '',
    vendorEmail: '',
    address: '',
    state: '',
    city: '',
    modeOfPayment: '',
    grandTotal: '',

  },
  errors: {},
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUES':
      return {
        ...state,
        formValues: { ...state.formValues, ...action.payload },
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: { ...state.errors, ...action.payload },
      };
    case 'RESET_FORM':
      return {
        ...state,
        formValues: initialState.formValues, storeName: '',
        errors: {},
      };
    default:
      return state;
  }
};

const apiurl = process.env.REACT_APP_API_URL;

const PurchaseFormat = () => {
  const [purchaseList, setpurchaseList] = useState([]);
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [purchaseId, setPurchaseId] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileName, setImageFileName] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [itemNo, setItemNo] = useState('');
  const [InvoiceData, setInvoiceData] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [addProductDialogOpen, setAddProductDialogOpen] = useState(false); //add product dialog box open
  const [othersAmount, setOthersAmount] = useState('');
  const [productsData, setproductsData] = useState([]);
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);
  //-------------unit& category================
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseInvoiceId, setPurchaseInvoiceId] = useState(null);
  const [stores, setStores] = useState([]);

  //=====================loading=====================================================
  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <CircularProgress />
    </div>
  );

  const [products, setProducts] = useState([{
    id: '',
    productName: '',
    unit: '',
    category: '',
    quantityOrdered: '',
    quantityReceived: '',
    unit: '',
    piecesOrdered: '',
    piecesReceived: '',
    differenceOfPieces: '',
    purchaseRate: '',
    rateOfPerPiece: '',
    amount: ''
  }]);


  // Update addProductLine function
  const isRowFilled = (product) => {
    const isFilled = product.amount;
    return isFilled;
  };


  const addProductLine = () => {
    if (products.length === 0 || isRowFilled(products[products.length - 1])) {
      const newProduct = {
        id: '',
        productName: '',
        category: '',
        quantityOrdered: '',
        quantityReceived: '',
        unit: '',
        piecesOrdered: '',
        piecesReceived: '',
        differenceOfPieces: '',
        purchaseRate: '',
        rateOfPerPiece: '',
        amount: ''
      };
      setProducts([...products, newProduct]);
    } else {
      alert("Please fill out the current row before adding a new one.");
    }
  };
  //===================================== formatIndianCurrency ======================================================================
  const formatIndianCurrency = (x) => {
    if (x === undefined || x === null) return '0.00';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };

  //====================================================================================

  // Update handleProductChange function
  const handleProductChange = (index, field, value) => {
    if (['quantityOrdered', 'quantityReceived', 'unit', 'purchaseRate'].includes(field)) {
      if (!/^\d*\.?\d*$/.test(value)) {
        // If the input is not a valid number, don't update the state
        return;
      }
    }
    const newProducts = [...products];
    newProducts[index][field] = value;

    if (field === 'productName') {
      const selectedProduct = productsData.find(p => p.id === value);
      if (selectedProduct) {
        newProducts[index] = {
          ...newProducts[index],
          id: selectedProduct.id,
          productName: selectedProduct.productName,
          category: selectedProduct.category,
        };
      }
    } else if (['purchaseRate', 'amount'].includes(field)) {
      // Store the numeric value in the state
      newProducts[index][field] = value;
      // Format the displayed value
      value = formatIndianCurrency(value);
    } else {
      newProducts[index][field] = value;
    }

    // Perform calculations
    const quantityOrdered = parseFloat(newProducts[index].quantityOrdered) || 0;
    const quantityReceived = parseFloat(newProducts[index].quantityReceived) || 0;
    const unit = parseFloat(newProducts[index].unit) || 0;
    const purchaseRate = parseFloat(newProducts[index].purchaseRate) || 0;

    // Calculate pieces ordered and received
    newProducts[index].piecesOrdered = (quantityOrdered * unit).toFixed(2);
    newProducts[index].piecesReceived = (quantityReceived * unit).toFixed(2);

    // Calculate difference of pieces
    newProducts[index].differenceOfPieces = (newProducts[index].piecesOrdered - newProducts[index].piecesReceived).toFixed(2);

    // Calculate rate per piece
    newProducts[index].rateOfPerPiece = unit !== 0 ? parseFloat(purchaseRate / unit).toFixed(2) : '0.00';

    // Calculate amount
    newProducts[index].amount = (parseFloat(newProducts[index].rateOfPerPiece) * parseFloat(newProducts[index].piecesReceived)).toFixed(2);

    setProducts(newProducts);

    // Recalculate grand total based on all products
    const grandTotal = newProducts.reduce((sum, product) => sum + parseFloat(product.amount || 0), 0).toFixed(2);

    // Update the form values with the new grand total
    dispatch({
      type: 'SET_FORM_VALUES',
      payload: { grandTotal: grandTotal }
    });
  };

  const handleOpenAddProductDialog = () => {   //-----------------------------------------------------------
    setAddProductDialogOpen(true);
  };

  const tableCellStyle = {
    height: '25px',
    padding: '4px',
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333'
  };


  const [query, setQuery] = useState('');

  const fetchPurchase = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    try {
      let url = new URL(`${apiurl}purchase`);

      // Add parameters to the URL
      url.searchParams.append('page', currentPage);
      url.searchParams.append('limit', rowsPerPage);

      if (fromDate) {
        url.searchParams.append('fromDate', fromDate);
      }
      if (toDate) {
        url.searchParams.append('toDate', toDate);
      }
      if (query) {
        url.searchParams.append('query', query);
      }

      console.log('Fetching URL:', url.toString()); // Log the full URL

      const response = await fetch(url.toString(), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch purchases');
      }

      const data = await response.json();
      const sortedPurchase = data.purchases.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setpurchaseList(sortedPurchase);
      localStorage.setItem('purchase', (data?.totalPurchase));

      const totalPages = data.numberOfPages || Math.ceil(data.totalPurchase / rowsPerPage);
      setTotalPages(totalPages);

    } catch (error) {
      console.error('Error fetching purchase:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    fetchPurchase();
    // setQuery(''); // Reset the search input after searching
  };

  useEffect(() => {

    fetchPurchase();
  }, [currentPage, rowsPerPage,]);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    fetchPurchase();

  };

  useEffect(() => {
    fetchPurchase();
  }, [currentPage, rowsPerPage,]);
  //----------------------------------


  //==================================================reset==============================
  // Add this new function to reset the form
  const resetForm = () => {
    dispatch({ type: 'RESET_FORM' });
    setProducts([{
      id: '',
      productName: '',
      category: '',

    }]);
  };

  const calculateTotals = () => {
    const totalAmount = products.reduce((sum, product) => sum + parseFloat(product.amount || 0), 0);
    const othersAmountValue = parseFloat(othersAmount) || 0;
    const subtotal = totalAmount + othersAmountValue;
    const grandTotal = Math.round(parseFloat(subtotal));

    return {
      grandTotal: grandTotal
    };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the value should be numeric
    if (['vendorContact'].includes(name)) {
      const isNumeric = /^\d*\.?\d*$/.test(value); // Allow numbers and decimals
      if (!isNumeric) return;
    }

    // Handle storeId separately to get the storeName
    if (name === 'storeId') {
      const selectedStore = stores.find(store => store.id === value);
      dispatch({
        type: 'SET_FORM_VALUES',
        payload: {
          ...state.formValues,
          storeId: value,
          storeName: selectedStore ? selectedStore.storeName : ''
        }
      });
      return; // Exit after handling storeId
    }

    // Update form values
    const newFormValues = { ...state.formValues, [name]: value };

    // Handle calculations for specific fields
    if (['quantityOrdered', 'quantityReceived', 'unit', 'purchaseRate'].includes(name)) {
      const quantityOrdered = parseFloat(newFormValues.quantityOrdered) || 0;
      const quantityReceived = parseFloat(newFormValues.quantityReceived) || 0;
      const unit = parseFloat(newFormValues.unit) || 0;
      const purchaseRate = parseFloat(newFormValues.purchaseRate) || 0;

      // Calculate pieces ordered and received
      newFormValues.piecesOrdered = (quantityOrdered * unit).toFixed(2);
      newFormValues.piecesReceived = (quantityReceived * unit).toFixed(2);

      // Calculate difference of pieces
      newFormValues.differenceOfPieces = (quantityOrdered * unit - quantityReceived * unit).toFixed(2);

      // Calculate rate per piece
      newFormValues.rateOfPerPiece = unit !== 0 ? (purchaseRate / unit).toFixed(2) : '0.00';

      // Calculate amount
      newFormValues.amount = (quantityReceived * unit * purchaseRate).toFixed(2);
    }

    // Dispatch updated form values
    dispatch({ type: 'SET_FORM_VALUES', payload: newFormValues });

    // Clear any errors for the changed field
    dispatch({ type: 'SET_ERRORS', payload: { [name]: '' } });
  };

  const validateForm = () => {
    const { orderNumber } = state.formValues;
    const newErrors = {};

    if (!orderNumber) newErrors.orderNumber = 'order No is required';
    // Only validate storeName if we're adding a new quotation (not updating)
    // if (editIndex === null && !state.formValues.storeName) {
    //   newErrors.storeName = 'Store Name is required';
    // }


    // Add validation for products
    if (products.length === 0) {
      newErrors.products = 'Product Name can not be empty';
    } else {
      // Check if all products have a valid id
      const invalidProducts = products.filter(product => !product.id || isNaN(parseInt(product.id)));
      if (invalidProducts.length > 0) {
        newErrors.products = 'Product Name can not be empty';
      }
    }

    dispatch({ type: 'SET_ERRORS', payload: newErrors });
    return Object.keys(newErrors).length === 0;
  };



  const handleAddOrUpdatepurchase = async (e) => {
    const token = localStorage.getItem('token');
    // console.log("handleAddOrUpdatepurchase called");

    e.preventDefault();
    setIsLoading(true);

    if (!validateForm()) {
      if (state.errors.products) {
        // Show snackbar for product selection or invalid ID error
        showAlert(state.errors.products, 'error');
      } else {
        setErrorSnackbarOpen(true);
      }
      setIsLoading(false);
      return;
    }

    const { grandTotal } = calculateTotals();


    const newPurchase = {
      orderNumber: state.formValues.orderNumber,
      // storeId: parseInt(state.formValues.storeId),
      vendorName: state.formValues.vendorName,
      vendorContact: state.formValues.vendorContact,
      vendorEmail: state.formValues.vendorEmail,
      address: state.formValues.address,
      state: state.formValues.state,
      city: state.formValues.city,
      modeOfPayment: state.formValues.modeOfPayment,
      grandTotal: parseFloat(grandTotal), // Add this line

      products: products.map(product => {
        console.log("Mapping product:", { id: (product) });
        return {
          id: parseInt(product.id),
          quantityOrdered: parseInt(product.quantityOrdered),
          quantityReceived: parseInt(product.quantityReceived),
          unit: parseInt(product.unit),
          piecesOrdered: parseInt(product.piecesOrdered),
          piecesReceived: parseInt(product.piecesReceived),
          differenceOfPieces: parseInt(product.differenceOfPieces),
          purchaseRate: parseInt(product.purchaseRate),
          rateOfPerPiece: parseInt(product.rateOfPerPiece),
          amount: parseFloat(product.amount),

        };
      })
    };
    // Add purchaseInvoiceId only when updating
    if (editIndex !== null) {
      newPurchase.purchaseInvoiceId = purchaseInvoiceId;
    }



    try {
      let response;
      if (editIndex !== null) {
        response = await fetch(`${apiurl}purchase/${purchaseId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newPurchase),
        });
      } else {
        // console.log("Adding new purchase");
        response = await fetch(`${apiurl}purchase`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newPurchase),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to add/update purchase: ${errorData.message || response.statusText}`);
      }

      const updatedPurchase = await response.json();
      // console.log('purchase added/updated successfully', newPurchaseData);

      setpurchaseList(prevList => {
        if (editIndex !== null) {
          // Update existing purchase
          showAlert('Purchase Data updated successfully', 'success');
          return prevList.map(purchase =>
            purchase._id === updatedPurchase._id ? updatedPurchase : purchase
          );
        } else {
          // Add new purchase
          showAlert('Purchase Data successfully', 'success');
          return [updatedPurchase, ...prevList];
        }
      });

      // Reset form and close dialog
      resetForm();
      setProducts([]);
      setShowForm(false);
      setEditIndex(null);

      fetchPurchase();
    } catch (error) {
      showAlert(`Failed to ${editIndex !== null ? 'update' : 'add'} purchase: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };


  const handlePreviewOpen = async (purchaseId) => {
    console.log('Opening preview for purchaseId:', purchaseId);
    try {
      const response = await fetch(`${apiurl}purchase/${purchaseId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch purchase details');
      const data = await response.json();
      console.log('Fetched purchase data:', data);
      setInvoiceData(data?.purchase);
      setPreviewOpen(true);
    } catch (error) {
      console.error('Error fetching purchase details:', error);
      showAlert('Failed to fetch purchase details', 'error');
    }
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setInvoiceData(null);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleConfirmDeleteOpen = (purchaseId) => {
    setDeleteIndex(purchaseId);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setDeleteIndex(null);
  };

  const handleDeletepurchase = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      if (!deleteIndex) {
        throw new Error('Invalid product selected for deletion');
      }

      const response = await fetch(`${apiurl}purchase/${deleteIndex}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete Purchase');
      }

      console.log('Purchase deleted successfully');

      // Update the Purchase list
      setpurchaseList((prevPurchaseList) => {
        const updatedList = prevPurchaseList.filter((purchase) => purchase._id !== deleteIndex);

        // If the current page is now empty and it's not the first page, go to the previous page
        if (updatedList.length === 0 && currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }

        return updatedList;
      });

      // Fetch the updated Purchase list
      fetchPurchase();

      showAlert('Purchase deleted successfully', 'success');

    } catch (error) {
      console.error('Error deleting Purchase:', error);
      showAlert(`Failed to delete Purchase: ${error.message}`, 'error');
    } finally {
      setConfirmDeleteOpen(false);
      setDeleteIndex(null);
      setIsLoading(false);
    }
  };

  const handlepurchaseEdit = (_id, index) => {
    setPurchaseId(_id);

    const actualIndex = (currentPage - 1) * itemsPerPage + index;
    // Use the _id directly to find the product instead of relying on the newly set state
    const purchaseToEdit = purchaseList.find(purchase => purchase._id === _id);

    setPurchaseInvoiceId(purchaseToEdit.purchaseInvoiceId); // Ensure this is set correctly

    if (!purchaseToEdit) {
      console.error('Sales not found:', _id, index, actualIndex);
      showAlert('Error: Sales not found', 'error');
      return;
    }

    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        orderNumber: purchaseToEdit?.orderNumber || '',
        // storeId: purchaseToEdit?.storeId || '',
        vendorName: purchaseToEdit?.vendorName || '',
        vendorContact: purchaseToEdit?.vendorContact || '',
        vendorEmail: purchaseToEdit?.vendorEmail || '',
        address: purchaseToEdit?.address || '',
        state: purchaseToEdit?.state || '',
        city: purchaseToEdit?.city || '',
        modeOfPayment: purchaseToEdit?.modeOfPayment || '',
        grandTotal: purchaseToEdit?.grandTotal || '',
      }
    });

    // Ensure that productDetails is an array and map over it directly
    if (Array.isArray(purchaseToEdit?.productDetails) && purchaseToEdit.productDetails.length > 0) {
      setProducts(purchaseToEdit.productDetails.map(product => ({
        _id: product?._id,
        id: product?.productId, // Add this line
        productName: product?.productName || '',
        category: product?.categoryName || '',
        quantityOrdered: product?.quantityOrdered ?? '', // Use nullish coalescing to handle null/undefined
        quantityReceived: product?.quantityReceived ?? '',
        unit: product?.unit ?? '',
        piecesOrdered: product?.piecesOrdered ?? '',
        piecesReceived: product?.piecesReceived ?? '',
        differenceOfPieces: product?.differenceOfPieces ?? '0', // Ensure 0 is shown if it's 0
        purchaseRate: product?.purchaseRate ?? '',
        rateOfPerPiece: product?.rateOfPerPiece ?? '',
        amount: product?.amount ?? '',
      })));
    } else {
      setProducts([]); // Reset products if there are none
    }
    setShowForm(true);
    setEditIndex(purchaseList.findIndex(purchase => purchase._id === _id));
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };


  //store define api======================================================================================
  const fetchstores = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiurl}store`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch stores');
      }
      const data = await response.json();

      if (data.status === 'success' && Array.isArray(data.stores)) {
        const storeList = data.stores.map(store => ({
          id: store.storeId, // या store.id, जो भी आपके API में उपयोग हो रहा है
          storeName: store.storeName,
          city: store.city
        }));
        setStores(storeList);
      } else {
        console.error('Unexpected data format:', data);
        setStores([]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setStores([]);
    }
  };
  useEffect(() => {
    fetchstores();
  }, []);

  useEffect(() => {
    console.log('Updated stores:', stores);
  }, [stores]);



  //==============================product define api============================================================
  const fetchproducts = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiurl}product/list`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      // console.log('Fetched product data:', data);

      if (data.status === 'success' && Array.isArray(data.products)) {
        const productList = data.products.map(product => ({
          id: product.productId,
          productName: product.productName,
          category: product.categoryName,
          unit: product.unit,
          purchaseRate: product.purchaseRate,
        }));
        setproductsData(productList);
      } else {
        // console.error('Unexpected data format:', data);
        setproductsData([]);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setproductsData([]);
    }
  };
  useEffect(() => {
    // console.log('Calling fetchproducts');
    fetchproducts();
  }, []);

  useEffect(() => {
    // console.log('Updated productsData:', productsData);
  }, [productsData]);

  //===================================================states define api======================================================
  const fetchStates = async () => {
    const token = localStorage.getItem('token');

    try {
      // console.log('Fetching states...');
      const response = await fetch(`${apiurl}state`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },

      });
      // console.log('Response status:', response.status);
      if (!response.ok) {
        throw new Error('Failed to fetch state');
      }
      const data = await response.json();
      // console.log('Fetched state data:', data);
      if (Array.isArray(data) && data.length > 0) {
        // console.log('First state item:', data[0]);
      } else {
        // console.log('Data is not an array or is empty');
      }
      setStates(data);
    } catch (error) {
      // console.error('Error fetching state:', error);
    }
  };

  useEffect(() => {
    // console.log('Calling fetchStates');
    fetchStates();
  }, []);



  // ===================================================category=============================================================================
  const handleAddCategory = async () => {
    if (newCategory.trim() !== '') {
      const token = localStorage.getItem('token');
      setIsLoading(true);
      try {
        const response = await fetch(`${apiurl}category`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name: newCategory })
        });
        if (!response.ok) {
          throw new Error('Failed to add category');
        }
        const data = await response.json();
        if (data.status === 'success') {
          // Update the categories state immediately
          setCategories(prevCategories => [...prevCategories, data.category]);
          handleInputChange({ target: { name: 'category', value: newCategory } });

          setNewCategory('');
          setShowCategoryDialog(false);
          showAlert('Category added successfully', 'success');
        }
      } catch (error) {
        console.error('Error adding category:', error);
        showAlert(`Failed to add category: ${error.message}`, 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  //=========================================================product api============================================================

  const handleAddOrUpdateProduct = async (e) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    const formData = new FormData();
    formData.append('productName', state.formValues.productName);
    const selectedCategory = categories.find(cat => cat.name === state.formValues.category);

    if (selectedCategory) {
      formData.append('categoryId', selectedCategory.categoryId);
    } else {
      console.error('Selected category not found');
      showAlert('Invalid category selected', 'error');
      setIsLoading(false);
      return;
    }
    if (imageFile) {
      formData.append('image', imageFile);
    } else {
      formData.append('image', state.formValues.image);
    }

    try {
      const response = await fetch(`${apiurl}product`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to add product: ${errorData.message || response.statusText}`);
      }

      const newProduct = await response.json();

      // Update the productsData state
      setproductsData(prevProducts => [...prevProducts, newProduct]);

      // Reset form fields
      dispatch({ type: 'RESET_FORM' });
      setImageFile(null);
      setImageFileName('');

      // Show success alert
      showAlert('Product added successfully', 'success');

      // Close the add product dialog
      setAddProductDialogOpen(false);

      // Fetch updated product list
      await fetchproducts();

    } catch (error) {
      console.error('Error adding product:', error);
      showAlert(`Failed to add product: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImageFileName(file.name);
    }
  };

  //===========================================================search product=========================================================


  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchProduct = (event) => {
    setSearchTerm(event.target.value);
  };

  const isProductMatch = (product) => {
    if (!searchTerm) return false; // Don't highlight if there's no search term
    return Object.values(product).some(value =>
      value && value.toString().includes(searchTerm)
    );
  };

  //===========================================================Delete product=========================================================

  const removeProductLine = async (product) => {
    const token = localStorage.getItem('token');

    // Use the _id field from the product
    const productDetailId = product._id;

    // Immediately remove the product from the UI, even if the ID is missing
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.filter((p) => p !== product);
      console.log('Updated products list:', updatedProducts);
      return updatedProducts;
    });

    // If the product has an _id, proceed with the API call to delete it from the server
    if (productDetailId) {
      try {
        console.log('Attempting to delete product with _id:', productDetailId);

        const response = await fetch(`${apiurl}purchase/product/${productDetailId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Server response:', errorData);
          console.error('Response status:', response.status);
          console.error('Response statusText:', response.statusText);
          throw new Error(errorData.message || `Failed to delete product: ${response.statusText}`);
        }

        console.log('Product deleted from the server successfully');
      } catch (error) {
        console.error('Error deleting product from the server:', error);
        throw error;
      }
    } else {
      console.warn('Product does not have an _id, skipping server deletion');
    }
  };
  //===============================================================================================================================
  return (
    <div className={styles.container}>
      <div className={styles.maincontainer}>
        {isLoading && <LoadingOverlay />}
        <Grid container spacing={2} >
          <Grid item>
            <TextField
              name="fromDate"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true // This ensures the label stays above the input value

              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="toDate"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true // This ensures the label stays above the input value
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="query"
              label="Search by Vendor Name or Contact"
              value={query}
              onChange={handleQueryChange}
              InputProps={{
                className: styles.invoiceinput,
              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdSearch />}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>

          <Grid item >
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<AddShoppingCartIcon />}
              onClick={() => setShowForm(true)}
            >
              Add Purchase
            </Button>
          </Grid>
        </Grid>

        <div className={styles.containers}>
          <Dialog
            open={showForm}
            onClose={() => { }}
            maxWidth="lg"
            fullWidth
            PaperProps={{
              sx: {
                maxWidth: 'auto',
                maxHeight: 'auto',
                margin: 'auto',
                mt: 0, // Set the margin-top to 0
              },
            }}
            sx={{
              display: 'flex',
              alignItems: 'flex-start', // Align dialog to the top
              justifyContent: 'center',
            }}
          >
            <DialogContent>
              <div className={styles.invoicemaincontainer}>
                <div className={styles.invoicecontentContainer}>
                  <div className={styles.searchProduct}>
                    <h2 className={styles.invoiceheader}>Purchase Data</h2>
                    <TextField
                      label="Search"
                      variant="outlined"
                      value={searchTerm}
                      type='search'
                      onChange={handleSearchProduct}
                      fullWidth
                      InputProps={{
                        className: styles.invoiceinput,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        width: '300px', // Set a specific width for the search input
                        '& .MuiInputLabel-root': {
                          top: '-6px',
                          color: '#555',
                          '&.Mui-focused': {
                            color: '#009999',
                          },
                        },
                        '& .MuiInputBase-root': {
                          color: '#000', // Text color
                        },
                      }}
                    />
                  </div>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextField
                          name="orderNumber"
                          label="Order No"
                          value={state.formValues.orderNumber}
                          onChange={handleInputChange}
                          fullWidth
                          required
                          error={!!state.errors.orderNumber}
                          helperText={state.errors.orderNumber}
                          InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                              // Hide the default asterisk and apply custom styles
                              '& .MuiFormLabel-asterisk': {
                                color: 'red', // Customize the color of the asterisk
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Text color
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          name="vendorName"
                          label="Vendor Name"
                          value={state.formValues.vendorName}
                          onChange={handleInputChange}
                          fullWidth
                          error={!!state.errors.vendorName}
                          helperText={state.errors.vendorName}
                          InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Adjust text color if needed
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          name="vendorContact"
                          label="Vendor Contact"
                          value={state.formValues.vendorContact}
                          onChange={handleInputChange}
                          fullWidth
                          inputProps={{
                            pattern: "[0-9]*",
                            inputMode: "numeric",
                            maxLength: 10,
                          }}
                          error={!!state.errors.vendorContact}
                          helperText={state.errors.vendorContact}
                          InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Adjust text color if needed
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          name="vendorEmail"
                          label="Vendor Email"
                          value={state.formValues.vendorEmail}
                          onChange={handleInputChange}
                          fullWidth
                          error={!!state.errors.vendorEmail}
                          helperText={state.errors.vendorEmail}
                          InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Adjust text color if needed
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="address"
                          label="Address"
                          value={state.formValues.address}
                          onChange={handleInputChange}
                          fullWidth
                          error={!!state.errors.address}
                          helperText={state.errors.address}
                          InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Adjust text color if needed
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormControl fullWidth error={!!state.errors.state}>
                          <InputLabel sx={{ top: '-6px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>State</InputLabel>
                          <Select
                            name="state"
                            value={state.formValues.state}
                            onChange={(e) => {
                              handleInputChange(e);
                              // console.log('State updated:', e.target.value);
                            }}
                            label="State"
                            className={styles.invoiceinput}                  >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {Array.isArray(states) ? (
                              states.map((stateItem) => (
                                <MenuItem key={stateItem.id || stateItem._id} value={stateItem.state || stateItem.name}>
                                  {stateItem.state || stateItem.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value=""><em>No states available</em></MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          name="city"
                          label="City"
                          value={state.formValues.city}
                          onChange={handleInputChange}
                          fullWidth
                          error={!!state.errors.city}
                          helperText={state.errors.city}
                          InputProps={{ className: styles.invoiceinput }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                              color: '#555',
                              '&.Mui-focused': {
                                color: '#009999',
                              },
                            },
                            '& .MuiInputBase-root': {
                              color: '#000', // Adjust text color if needed
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormControl fullWidth error={!!state.errors.modeOfPayment}>
                          <InputLabel sx={{ top: '-6px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>Mode Of Payment</InputLabel>
                          <Select
                            name="modeOfPayment"
                            label="Mode of Payment"
                            value={state.formValues.modeOfPayment}
                            onChange={handleInputChange}
                            className={styles.invoiceinput}
                          >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value="CASH">Cash</MenuItem>
                            <MenuItem value="UPI">UPI</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <div className={styles.tableContainer}>
                    <table className={styles.invoicetabledata}>
                      <thead>
                        <tr>
                          <th className={styles.invoiceTh}>Sr_No</th>
                          <th className={styles.invoiceTh}>Product Name</th>
                          <th className={styles.invoiceTh}>Category</th>
                          <th className={styles.invoiceTh}>Qty Od</th>
                          <th className={styles.invoiceTh}>Qty Rc</th>
                          <th className={styles.invoiceTh}>Unit</th>
                          <th className={styles.invoiceTh}>Pcs Od</th>
                          <th className={styles.invoiceTh}>Pcs Rc</th>
                          <th className={styles.invoiceTh}>Diff Pcs</th>
                          <th className={styles.invoiceTh}>Rate(₹)</th>
                          <th className={styles.invoiceTh}>Rs of Pcs(₹)</th>
                          <th className={styles.invoiceTh}>Amount(₹)</th>
                          <th className={styles.invoiceTh}>Action</th>
                        </tr>
                      </thead>


                      <tbody>
                        {products.map((product, index) => {
                          const selectedProductIds = products.map(p => p.id);
                          const isMatch = isProductMatch(product);

                          return (
                            <tr key={index} style={{ backgroundColor: isMatch ? '#bff6bb' : 'inherit' }}>
                              <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ddd' }}>
                                {index + 1}
                              </td>

                              <td className={styles.invoiceTd}>
                                <Autocomplete
                                
                                  options={[{ id: 'add-new', productName: '+ Add a New Product', category: '', purchaseRate: '' }, ...productsData]}
                                  getOptionLabel={(option) => option.productName || ''}
                                  value={productsData.find(p => p.id === product.id) || null}
                                  onChange={(event, newValue) => {
                                    if (newValue && newValue.id === 'add-new') {
                                      setAddProductDialogOpen(true); // Open dialog for adding a new product
                                    } else {
                                      handleProductChange(index, 'id', newValue ? newValue.id : '');
                                      handleProductChange(index, 'productName', newValue ? newValue.productName : '');
                                      handleProductChange(index, 'category', newValue ? newValue.category : '');
                                      handleProductChange(index, 'purchaseRate', newValue ? newValue.purchaseRate : '');
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Product"
                                      variant="outlined"
                                      size="small"
                                      sx={{
                                        top:0,
                                        width: '220px',
                                        '& .MuiOutlinedInput-root': {
                                          height: "30px",
                                          borderRadius: '0',
                                          '& fieldset': { borderColor: '#888' },
                                          '&.Mui-focused fieldset': { borderColor: '#333' },
                                        },
                                        '& .MuiInputBase-input': { width: "100%" },
                                      }}
                                    />
                                  )}
                                  renderOption={(props, option) => (
                                    <li
                                      {...props}
                                      style={{
                                        backgroundColor: option.id === 'add-new' ? '#009999' : 'white',
                                        fontWeight: option.id === 'add-new' ? 'semibold' : 'normal',
                                        color: option.id === 'add-new' ? '#ffffff' : '#000000', // White text color for 'Add a New Product'
                                      }}
                                    >
                                      {option.productName}
                                    </li>
                                  )}
                                  filterOptions={(options, { inputValue }) =>
                                    options.filter(option =>
                                      option.productName.toLowerCase().startsWith(inputValue.toLowerCase())
                                    )
                                  }
                                />

                              </td>

                              <td className={styles.invoiceTd}>
                                <input
                                  type="text"
                                  value={product.category || ''}
                                  disabled
                                  className={styles.invoiceinputs}
                                />
                              </td>

                              <td className={styles.invoiceTd}>
                                <input

                                  name="quantityOrdered"
                                  value={product.quantityOrdered}
                                  onChange={(e) => handleProductChange(index, 'quantityOrdered', e.target.value)}
                                  className={styles.invoiceinputs}
                                />
                              </td>

                              <td className={styles.invoiceTd}>
                                <input
                                  name="quantityReceived"
                                  value={product.quantityReceived}
                                  onChange={(e) => handleProductChange(index, 'quantityReceived', e.target.value)}
                                  className={styles.invoiceinputs}
                                />
                              </td>

                              <td className={styles.invoiceTd}>
                                <input
                                  name="unit"
                                  value={product.unit}
                                  onChange={(e) => handleProductChange(index, 'unit', e.target.value)}
                                  className={styles.invoiceinputs}
                                />
                              </td>

                              <td className={styles.invoiceTd}>
                                <input
                                  name='piecesOrdered'
                                  value={product.piecesOrdered}
                                  disabled
                                  className={styles.invoiceinputs}
                                />
                              </td>

                              <td className={styles.invoiceTd}>
                                <input
                                  name='piecesReceived'
                                  value={product.piecesReceived}
                                  disabled
                                  className={styles.invoiceinputs}
                                />
                              </td>

                              <td className={styles.invoiceTd}>
                                <input
                                  name='differenceOfPieces'
                                  value={product.differenceOfPieces}
                                  disabled
                                  className={styles.invoiceinputs}
                                />
                              </td>

                              <td className={styles.invoiceTd}>
                                <input
                                  name="purchaseRate"
                                  type="text"
                                  value={formatIndianCurrency(product.purchaseRate)}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/,/g, ''); // Remove commas if any

                                    // Allow only numbers and ensure it doesn't exceed 11 digits
                                    if (value === '' || /^\d{1,11}(\.\d{0,2})?$/.test(value)) {
                                      handleProductChange(index, 'purchaseRate', value); // Call the change handler with valid input
                                    }
                                  }} className={styles.invoiceinputs}
                                  style={{ textAlign: 'right' }}
                                />
                              </td>

                              <td className={styles.invoiceTd}>
                                <input
                                  name='rateOfPerPiece'
                                  value={formatIndianCurrency(product.rateOfPerPiece)}
                                  disabled
                                  className={styles.invoiceinputs}
                                  style={{ textAlign: 'right' }}
                                />
                              </td>

                              <td className={styles.invoiceTd}>
                                <input
                                  name='amount'
                                  value={formatIndianCurrency(product.amount)}
                                  disabled
                                  className={styles.invoiceinputs}
                                  style={{ textAlign: 'right' }}
                                />
                              </td>

                              <td className={styles.invoiceTd}>
                                <IconButton

                                  onClick={(event) => showDeleteConfirmation(() => removeProductLine(product))}
                                  className={styles.deleteButton}

                                >

                                  <ClearIcon className={styles.invoiceicons} />
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <Button sx={{
                    backgroundColor: '#009999',
                    color: '#fff',
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#01dcb8',
                    },
                  }}
                    onClick={addProductLine}>+ Add Line</Button>
                  <div className={styles.summary}>
                    <div>Grand Total: <span>₹{formatIndianCurrency(calculateTotals().grandTotal)}</span></div>
                  </div>

                  <DialogActions sx={{ mt: 2, justifyContent: "flex-start" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: '#009999',
                        color: '#fff',
                        textTransform: 'capitalize',
                        '&:hover': {
                          backgroundColor: '#01dcb8',
                        },
                      }}
                      onClick={handleAddOrUpdatepurchase}
                      startIcon={<AddIcon />}

                    >
                      {editIndex !== null ? 'Update Purchase Data' : 'Add Purchase Data'}
                    </Button>

                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: '#e32e2e',
                        color: "fff",
                        textTransform: 'capitalize',
                        '&:hover': {
                          backgroundColor: '#ff0000',
                        },
                      }}

                      onClick={() => {
                        resetForm();
                        setShowForm(false);
                        setEditIndex(null);
                      }}
                      startIcon={<CloseIcon />}

                    >

                      Cancel
                    </Button>

                  </DialogActions>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>

        {addProductDialogOpen && (
          <DialogProduct
            showForm={addProductDialogOpen}
            onClose={() => setAddProductDialogOpen(false)}
            onProductAdded={(newProduct) => {
              setAddProductDialogOpen(false);
            }}
            state={state}
            itemNo={itemNo}
            categories={categories}
            handleInputChange={handleInputChange}
            editIndex={editIndex}
            setCategories={setCategories}
            onSubmit={handleAddOrUpdateProduct}
            imageFileName={imageFileName}
            imageFile={imageFile}
            handleImageChange={handleImageChange}
            dispatch={dispatch}  // Add this line
            isLoading={isLoading}
            handleAddCategory={handleAddCategory}
            newCategory={newCategory}
            setNewCategory={setNewCategory}
            showCategoryDialog={showCategoryDialog}
            setShowCategoryDialog={setShowCategoryDialog}
          />

        )}

        <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
          <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
          <Select
            labelId="rows-per-page-label"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            label="Rows per page"
          >
            {[10, 25, 50, 100].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={styles.tablecontainer}>
          <TableContainer component={Paper}>
            <Table className={styles.storetable}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Order No</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Vendor Name</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Vendor Contact</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Vendor Address</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Quantity ordered</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Quantity recieved</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Difference</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Date</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {purchaseList?.map((purchase, index) => {
                  // console.log("purchase", purchase)

                  return (<TableRow key={purchase._id} style={{ backgroundColor: !purchase.isActive ? '#ffcccc' : index % 2 === 0 ? 'white' : '#f0f0f0' }}>

                    <TableCell style={tableCellStyle}>{(index + 1 + (currentPage - 1) * itemsPerPage)
                      .toString().padStart(4, '0')}</TableCell>

                    <TableCell style={tableCellStyle}> {purchase.orderNumber}</TableCell>
                    <TableCell style={tableCellStyle} >{purchase.vendorName}</TableCell>
                    <TableCell style={tableCellStyle} >{purchase.vendorContact}</TableCell>
                    <TableCell style={tableCellStyle} >{purchase.address}</TableCell>
                    <TableCell style={tableCellStyle} >{purchase.totalOrderQty}</TableCell>
                    <TableCell style={tableCellStyle} >{purchase.totalReceivedQty}</TableCell>
                    <TableCell style={tableCellStyle} >{purchase.totalDifference}</TableCell>

                    <TableCell style={tableCellStyle}>{purchase.createdAt}</TableCell>
                    <TableCell style={tableCellStyle} >
                      <IconButton onClick={() => handlePreviewOpen(purchase._id)} >
                        <Preview className={styles.prew} />
                      </IconButton>
                      <IconButton onClick={() => handlepurchaseEdit(purchaseList[index]._id, index + (currentPage - 1) * itemsPerPage)} >
                        <Edit className={styles.editIcon} />
                      </IconButton>
                      <IconButton onClick={() => handleConfirmDeleteOpen(purchase._id)} >
                        <Delete className={styles.editDelete} />
                      </IconButton>
                    </TableCell>

                  </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>

            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#009999',
                  border: '1px solid #ddd',
                  margin: '0 4px',
                  borderRadius: '4px',
                },
                '& .Mui-selected': {
                  backgroundColor: '#009999',
                  color: 'white',
                },
              }}
            />
          </Box>

        </div>

        {/* ==================================================================invoice================================================================================================================= */}

        <Dialog open={previewOpen} onClose={handlePreviewClose}
          PaperProps={{
            sx: { minWidth: '100%', minHeight: '100%' }
          }}>
          <DialogContent>
            <div key={InvoiceData?._id}>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h1 style={{ fontSize: "22px", color: "#808080" }}> Cash Memo </h1>
                </div>
                <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                    <div className={styles.invoicetop} style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={fl} alt="Store Logo" className={styles.storeLogo} style={{ marginRight: '10px' }} />
                      <h2 style={{ margin: 0 }}>FL Lights</h2>
                    </div>
                    <div className={styles.companyDetails} style={{ marginTop: '10px' }}>
                      <p>[Lohar Chawl, Mumbai - 400002]</p>
                      <p>Phone: [07942684958] </p>
                    </div>
                  </div>
                  <div style={{ textAlign: 'right', marginLeft: 'auto' }}>
                    <p>Order No :- {InvoiceData?.orderNumber}</p>
                    <p>Date :- {InvoiceData?.createdAt}</p>
                    <h3>Vendor Details:</h3>
                    <p>{InvoiceData?.vendorName}</p>
                    <p>{InvoiceData?.address}</p>
                    <p>{InvoiceData?.city}, {InvoiceData?.state}</p>
                    <p>{InvoiceData?.vendorContact}</p>
                  </div>

                </header>
                <Divider />
                <table className={styles.productsTable}>
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Product Name</th>
                      <th>Category</th>
                      <th>Qty Od</th>
                      <th>Qty Rc</th>
                      <th>Unit</th>
                      <th>Pcs Od</th>
                      <th>Pcs Rc</th>
                      <th>Diff Pcs</th>
                      <th>Rate(₹)</th>
                      <th>Rs of Pcs(₹)</th>
                      <th>Amount(₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {InvoiceData?.productDetails?.map((product, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td> {/* Serial Number */}
                        <td>{product.productName}</td>
                        <td>{product.categoryName}</td>
                        <td>{product.quantityOrdered}</td>
                        <td>{product.quantityReceived}</td>
                        <td>{product.unit}</td>
                        <td>{product.piecesOrdered}</td>
                        <td>{product.piecesReceived}</td>
                        <td>{product.differenceOfPieces}</td>
                        <td>{formatIndianCurrency(product.purchaseRate)}</td>
                        <td>{formatIndianCurrency(product.rateOfPerPiece)}</td>
                        <td>{formatIndianCurrency(product.amount)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className={styles.totals}>
                  <div>
                    <p><strong>TOTAL Amount :- ₹</strong></p>
                  </div>
                  <div className={styles.fonts}>
                    <p><strong>{formatIndianCurrency(InvoiceData?.grandTotal)}</strong></p>
                  </div>
                </div>
                <footer className={styles.footer}>
                  <p>Make all checks payable to [FL Lights]</p>
                  <p>If you have any questions concerning this invoice, contact [FL Lights, 07942684958]</p>
                  <h2>THANK YOU FOR YOUR BUSINESS!</h2>
                </footer>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={styles.noprint}>
            <Button variant="contained" onClick={handlePreviewClose} sx={{ backgroundColor: '#f44336', color: '#fff' }}>Close</Button>
            <Button variant="contained" onClick={handlePrint} sx={{ backgroundColor: '#4caf50', color: '#fff' }} startIcon={<MdPrint />}>Print</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>

          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            Please fill in all fields correctly.
          </Alert>
        </Snackbar>

        <Dialog
          open={confirmDeleteOpen}
          onClose={handleConfirmDeleteClose}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this product?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleConfirmDeleteClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDeletepurchase} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>


        {/* ====================================================alert======================================================= */}

        {alertInfo.open && (
          <Alert
            severity={alertInfo.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertInfo({ ...alertInfo, open: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
            sx={{
              position: 'fixed',
              top: 16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
            }}
          >
            {alertInfo.message}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default PurchaseFormat;
