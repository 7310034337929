
import React, { useReducer, useState, useEffect } from 'react';
import {
  Button, Divider, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar,
  Box, Grid, Select, MenuItem, FormControl, InputLabel, Menu,
  FormHelperText
} from '@mui/material';
import { MdEdit, MdDeleteForever, MdPrint, MdOutlineAddchart, MdPreview, MdSearch, MdCheckCircle } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete } from '@mui/material';
import { CircularProgress, } from '@mui/material';
import fl from '../../assets/fl copy.png';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import styles from "../blockOrder/blockOrder.module.css";
import { Delete, ModeEditOutline, Preview } from '@mui/icons-material';
import { showDeleteConfirmation } from '../../DIALOG/sweetAlert';
import BlockInvoice from './blockInvoice';
import AddIcon from '@mui/icons-material/Add';


const apiurl = process.env.REACT_APP_API_URL;

const initialState = {
  formValues: {
    storeId: '',  // Add this line
    storeName: '',
    customerName: '',
    customerContact: '',
    advancePayment: '',
    customerAddress: '',
    quantity: '',
    amount: '',
    modeOfPayment: '',
    state: "",
    city: '',
    pinCode: '',
    extraAmountAdd: '',
    discount: '',
    grandTotal: '',
    balance: '',
    blockOrderId: '',
    createdAt: '',
  },
  errors: {},
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUES':
      return { ...state, formValues: { ...state.formValues, ...action.payload } };
    case 'SET_ERRORS':
      return { ...state, errors: { ...state.errors, ...action.payload } };
    case 'RESET_FORM':
      return { ...state, formValues: initialState.formValues, errors: {} };
    default:
      return state;
  }
};

const BlockOrder = () => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [blockId, setblockId] = useState(null);
  const [blockList, setBlockList] = useState([]);
  const [detailsList, setDetailsList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBlockIndex, setSelectedBlockIndex] = useState(null);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [itemNo, setItemNo] = useState('');
  const [blockData, setBlockData] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [othersAmount, setOthersAmount] = useState('');
  const [stores, setStores] = useState([]);
  const [productsData, setproductsData] = useState([]);
  const [states, setStates] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalStores, setTotalStores] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [blockOrderId, setblockOrderId] = useState(null);

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const storedRole = localStorage.getItem("roleName");
    setUserRole(storedRole || "");
  }, []);

  const hasPermission = (allowedRoles) => {
    const userRole = localStorage.getItem("roleName")?.toLowerCase();
    return allowedRoles.includes(userRole);
  };

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };

  //=================================================user api==============================================================
  const [userStore, setUserStore] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${apiurl}user/loggeduser`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUserRole(data.user.roleName.toLowerCase());
        if (data.user.roleName.toLowerCase() === 'store') {
          setUserStore({
            id: data.user.storeId,
            storeName: data.user.storeName
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  //=================================loading====================================================================
  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <CircularProgress />
    </div>
  );

  const [products, setProducts] = useState([{
    id: '',
    productName: '',
    category: '',
    rate: '',
    pieces: '',
    amount: ''
  }]);



  // Update addProductLine function
  const isRowFilled = (product) => {
    const isFilled =  product.amount;
    return isFilled;
  };


  const addProductLine = () => {
    if (products.length === 0 || isRowFilled(products[products.length - 1])) {
      const newProduct = {
        id: '',
        productName: '',
        category: '',
        rate: '',
        pieces: '',
        amount: ''
      };
      setProducts([...products, newProduct]);
    } else {
      alert("Please fill out the current row before adding a new one.");
    }
  };

  //===================================== formatIndianCurrency ======================================================================
  const formatIndianCurrency = (x) => {
    if (x === undefined || x === null) return '0.00';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };

  //====================================================================================


  const handleProductChange = (index, field, value) => {
    if (['rate', 'pieces',].includes(field)) {
      if (!/^\d*\.?\d*$/.test(value)) {
        // If the input is not a valid number, don't update the state
        return;
      }
    }

    const newProducts = [...products];

    if (field === 'productName') {
      const selectedProduct = productsData.find(p => p.id === value);
      if (selectedProduct) {
        newProducts[index] = {
          ...newProducts[index],
          id: selectedProduct.id,
          productName: selectedProduct.productName,
          category: selectedProduct.category,
          rate: selectedProduct.rate,
        };
      }
    } else {
      newProducts[index][field] = value;
    }

    // Calculate amount if rate and pieces are present
    if (newProducts[index].rate && newProducts[index].pieces) {
      const rate = parseFloat(newProducts[index].rate);
      const pieces = parseFloat(newProducts[index].pieces) || 0;
      const discount = parseFloat(newProducts[index].discount) || 0;
      const amount = (rate * pieces) - discount;
      newProducts[index].amount = amount.toFixed(2);
    }

    setProducts(newProducts);
  };


  const tableCellStyle = {
    height: '25px',
    padding: '4px',  // Ensure no extra padding affects height
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333'

  };

  const calculateTotals = () => {
    const subtotal = products.reduce((sum, product) => {
      const amount = parseFloat(product.amount || 0);
      return sum + amount;
    }, 0);

    const extraAmountAddValue = parseFloat(othersAmount) || 0;
    const discountValue = parseFloat(totalDiscount) || 0;
    const subtotalWithExtras = subtotal + extraAmountAddValue;
    const grandTotal = Math.max(subtotalWithExtras - discountValue, 0).toFixed(2);
    const advancePayment = parseFloat(state.formValues.advancePayment) || 0;
    const balance = Math.max(grandTotal - advancePayment, 0).toFixed(2);

    return {
      subtotal: subtotal.toFixed(2),
      grandTotal: grandTotal,
      balance: balance
    };
  };

  const [query, setQuery] = useState('');

  const fetchblockOrder = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const baseUrl = `${apiurl}blockOrder`;
      const params = new URLSearchParams({
        page: currentPage.toString(),
        limit: rowsPerPage.toString()
      });

      if (fromDate) params.append('fromDate', fromDate);
      if (toDate) params.append('toDate', toDate);
      if (query) params.append('query', query);

      const url = `${baseUrl}?${params.toString()}`;

      console.log('Fetching URL:', url); // Log the full URL

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch blockOrder');
      }

      const data = await response.json();
      setBlockList(data.blockOrders);
      setTotalStores(data.totalOrders)
      if (data.status === 'success' && Array.isArray(data.blockOrders)) {
        const sortedOrders = data.blockOrders
          .filter(order => order !== null && order !== undefined) // Add this filter
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setBlockList(sortedOrders);

        // Update total pages
        const totalPages = data.numberOfPages || Math.ceil(data.totalOrders / rowsPerPage);
        setTotalPages(totalPages);
        // ... rest of the function remains the same
      } else {
        console.error('Unexpected data structure:', data);
        setBlockList([]);
      }
      if (data.totalOrders) {
        localStorage.setItem('staff', data.totalOrders.toString());
      }


    } catch (error) {
      console.error('Error fetching block orders:', error);
      setBlockList([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    fetchblockOrder();
    // setQuery(''); // Reset the search input after searching

  };



  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    fetchblockOrder();
    // Call this to fetch data for the new page
  };

  useEffect(() => {
    fetchblockOrder();
  }, [currentPage, rowsPerPage]);

  //==================================================reset==============================
  // Add this new function to reset the form
  const resetForm = () => {
    dispatch({ type: 'RESET_FORM' });
    setProducts([{
      id: '',
      productName: '',
      category: '',
      rate: '',
      pieces: '',
      amount: ''
    }]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the value should be numeric
    if (['customerContact', 'advancePayment', 'totalDiscount',].includes(name)) {
      const isNumeric = /^\d*\.?\d*$/.test(value); // Allow numbers and decimals
      if (!isNumeric) return;
    }

    if (name === 'storeId') {
      if (userRole === 'store') {
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: {
            storeId: userStore.id,
            storeName: userStore.storeName
          }
        });
      } else {
        const selectedStore = stores.find(store => store.id === value);
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: {
            storeId: value,
            storeName: selectedStore ? selectedStore.storeName : ''
          }
        });
      }
    } else {
      dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: value } });
    }
    dispatch({ type: 'SET_ERRORS', payload: { [name]: '' } });
  };

  const validateForm = () => {
    const { storeId, customerName, customerContact, modeOfPayment, } = state.formValues;
    const newErrors = {};
    console.log(state.formValues)
    if (!customerName) newErrors.customerName = 'Customer Name is required';
    if (!customerContact) newErrors.customerContact = 'Contact is required';
    if (!modeOfPayment) newErrors.modeOfPayment = 'Mode of Payment is required';


    // Only validate storeId for admin and staff roles
    if (userRole !== 'store' && !storeId) {
      newErrors.storeId = 'Store Name is required';
    }

    console.log('Validation errors:', newErrors);

    dispatch({ type: 'SET_ERRORS', payload: newErrors });
    return Object.keys(newErrors).length === 0;
  };


  const handleAddOrUpdateOrders = async (e) => {
    const token = localStorage.getItem('token');
    if (!hasPermission(['admin', 'super-admin', 'store'])) {
      showAlert('You do not have permission to perform this action.', 'error');
      return;
    }

    setIsLoading(true);
    e.preventDefault();

    if (!validateForm()) {
      setErrorSnackbarOpen(true);
      setErrorMessage('Form validation failed');
      setIsLoading(false);

      return;
    }

    const newOrders = {

      storeId: userRole === 'store' ? userStore.id : parseInt(state.formValues.storeId),
      customerName: state.formValues.customerName,
      customerContact: state.formValues.customerContact,
      customerAddress: state.formValues.customerAddress,
      city: state.formValues.city,
      state: state.formValues.state,
      pinCode: state.formValues.pinCode,
      advancePayment: parseFloat(state.formValues.advancePayment) || 0,
      date: state.formValues.date,
      modeOfPayment: state.formValues.modeOfPayment,
      extraAmountAdd: parseFloat(othersAmount) || 0,
      grandTotal: parseFloat(calculateTotals().grandTotal),
      discount: parseFloat(totalDiscount) || 0,
      balance: parseFloat(calculateTotals().balance),

      products: products?.map(product => {
        console.log("Mapping product:", { id: (product) });
        return {
          id: parseInt(product.id),
          pieces: parseInt(product.pieces),
          rate: parseFloat(product.rate),
          amount: parseFloat(product.amount)

        };
      })
    };

    // Add blockOrderId only when updating
    if (editIndex !== null) {
      newOrders.blockOrderId = blockOrderId;
    }

    try {
      let response;
      if (editIndex !== null) {
        // Update existing orders

        response = await fetch(`${apiurl}blockOrder/${blockId}`, {
          method: 'PUT',

          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },

          body: JSON.stringify(newOrders),
        });

      } else {
        // Add new orders
        response = await fetch(`${apiurl}blockOrder`, {
          method: 'POST',

          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },

          body: JSON.stringify(newOrders),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to add/update blockOrder: ${errorData.message || response.statusText}`);
      }

      const updateBlockOrder = await response.json();
      // console.log('blockOrder added/updated successfully', newblockOrderData);

      setBlockList(prevList => {
        if (editIndex !== null) {
          // Update existing blockOrder
          showAlert('Block Order updated successfully', 'success');
          return prevList?.map(blockOrder =>
            blockOrder._id === updateBlockOrder._id ? updateBlockOrder : blockOrder
          );
        } else {
          // Add new blockOrder
          showAlert('Block Order added successfully', 'success');

          return [updateBlockOrder, ...prevList];
        }
      });

      // Reset form and close dialog
      resetForm();
      setProducts([{ ...initialState.formValues }]);
      setShowForm(false);
      setEditIndex(null);

      fetchblockOrder();
    } catch (error) {
      showAlert(`Failed to ${editIndex !== null ? 'update' : 'add'} product: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewOpen = async (blockId) => {

    try {
      const response = await fetch(`${apiurl}blockOrder/${blockId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch Orders details');

      const data = await response.json();
      console.log('Orders details:', data);

      setBlockData(data?.blockOrder);
      setPreviewOpen(true);
    } catch (error) {
      console.error('Error fetching Orders details:', error);
    }
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setBlockData(null);
  };

  const handlePrint = () => {
    window.print();
  };


  const handleConfirmDeleteOpen = (blockId) => {
    setDeleteIndex(blockId);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setDeleteIndex(null);
  };

  const handleDeleteOrders = async () => {
    if (!hasPermission(['admin', 'super-admin', 'store'])) {
      showAlert('You do not have permission to delete orders.', 'error');
      return;
    }
    const token = localStorage.getItem('token');
    setIsLoading(true);

    try {
      if (!deleteIndex) {
        throw new Error('Invalid block order selected for deletion');
      }
      const response = await fetch(`${apiurl}blockOrder/${deleteIndex}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete Order');
      }

      console.log('Block Order deleted successfully');

      // Update the block order list
      setBlockList((prevBlockList) => {
        const updatedList = prevBlockList.filter((blockOrder) => blockOrder._id !== deleteIndex);

        // If the current page is now empty and it's not the first page, go to the previous page
        if (updatedList.length === 0 && currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }

        return updatedList;
      });

      // Fetch the updated product list
      fetchblockOrder();

      showAlert('Block order deleted successfully', 'success');

    } catch (error) {
      console.error('Error deleting block order:', error);
      showAlert(`Failed to delete block order: ${error.message}`, 'error');
    } finally {
      setConfirmDeleteOpen(false);
      setDeleteIndex(null);
      setIsLoading(false);
    }
  };

  const handleStatusChange = (index, newStatus) => {
    if (!hasPermission(['admin', 'super-admin', 'store'])) {
      showAlert('You do not have permission to change order status.', 'error');
      return;
    }
    setBlockList(prevList => {
      const newList = [...prevList];
      newList[index] = { ...newList[index], status: newStatus };
      return newList;
    });
  };

  const handleEditOrders = (_id, index) => {
    setblockId(_id);

    const actualIndex = (currentPage - 1) * itemsPerPage + index;
    // Use the _id directly to find the product instead of relying on the newly set state
    const detailsToEdit = blockList.find(blockOrder => blockOrder._id === _id);

    setblockOrderId(detailsToEdit.blockOrderId); // Ensure this is set correctly

    if (!detailsToEdit) {
      console.error('Block order not found:', _id, index, actualIndex);
      showAlert('Error: Block order not found', 'error');
      return;
    }

    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        storeId: detailsToEdit?.storeId,
        customerName: detailsToEdit?.customerName || '',
        customerContact: detailsToEdit?.customerContact || '',
        customerAddress: detailsToEdit?.customerAddress || '',
        state: detailsToEdit?.state || '',
        city: detailsToEdit?.city || '',
        modeOfPayment: detailsToEdit?.modeOfPayment || '',
        advancePayment: detailsToEdit?.advancePayment || '',
        pinCode: detailsToEdit?.pinCode || '',
      }
    });

    if (detailsToEdit?.productDetails && detailsToEdit?.productDetails.length > 0) {
      console.log("purchaseData", detailsToEdit.products);
      setProducts(detailsToEdit?.productDetails.map(product => ({
        _id: product?._id,
        id: product?.productId,
        productName: product?.productName || '',
        category: product?.categoryName || '',
        rate: product?.rate || '',
        pieces: product?.pieces || '',
        discount: product?.discount || '',
        amount: product?.amount || '',
        balance: product?.balance || ''
      })));
    } else {
      setProducts([]); // Reset products if there are none
    }

    setShowForm(true);
    setEditIndex(blockList.findIndex(sales => sales._id === _id));
  };


  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedBlockIndex(null);
  };

  //store define api
  const fetchstores = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiurl}store`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch stores');
      }
      const data = await response.json();
      console.log('Fetched store data:', data);

      if (data.status === 'success' && Array.isArray(data.stores)) {
        const storeList = data.stores.map(store => ({
          id: store.storeId, // या store.id, जो भी आपके API में उपयोग हो रहा है
          storeName: store.storeName,
          city: store.city
        }));
        setStores(storeList);
      } else {
        console.error('Unexpected data format:', data);
        setStores([]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setStores([]);
    }
  };
  useEffect(() => {
    fetchstores();
  }, []);

  useEffect(() => {
    console.log('Updated stores:', stores);
  }, [stores]);


  //product define api
  const fetchproducts = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiurl}product/list`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      console.log('Fetched product data:', data);

      if (data.status === 'success' && Array.isArray(data.products)) {
        const productList = data.products.map(product => ({
          id: product.productId,
          productName: product.productName,
          category: product.categoryName,
          unit: product.unitName,
          rate: product.sellRate,
        }));
        setproductsData(productList);
      } else {
        console.error('Unexpected data format:', data);
        setproductsData([]);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setproductsData([]);
    }
  };
  useEffect(() => {
    console.log('Calling fetchproducts');
    fetchproducts();
  }, []);

  useEffect(() => {
    console.log('Updated productsData:', productsData);
  }, [productsData]);


  //states api fetch 
  const fetchStates = async () => {
    const token = localStorage.getItem('token');

    try {
      console.log('Fetching states...');
      const response = await fetch(`${apiurl}state`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      console.log('Response status:', response.status);
      if (!response.ok) {
        throw new Error('Failed to fetch state');
      }
      const data = await response.json();
      console.log('Fetched state data:', data);
      if (Array.isArray(data) && data.length > 0) {
        console.log('First state item:', data[0]);
      } else {
        console.log('Data is not an array or is empty');
      }
      setStates(data);
    } catch (error) {
      console.error('Error fetching state:', error);
    }
  };
  useEffect(() => {
    console.log('Calling fetchStates');
    fetchStates();
  }, []);

  //====================================remove product===============================================================

  const removeProductLine = async (product) => {
    const token = localStorage.getItem('token');

    // Use the _id field from the product
    const productDetailId = product._id;

    // Immediately remove the product from the UI, even if the ID is missing
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.filter((p) => p !== product);
      console.log('Updated products list:', updatedProducts);
      return updatedProducts;
    });

    // If the product has an _id, proceed with the API call to delete it from the server
    if (productDetailId) {
      try {
        console.log('Attempting to delete product with _id:', productDetailId);

        const response = await fetch(`${apiurl}blockOrder/product/${productDetailId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Server response:', errorData);
          console.error('Response status:', response.status);
          console.error('Response statusText:', response.statusText);
          throw new Error(errorData.message || `Failed to delete product: ${response.statusText}`);
        }

        console.log('Product deleted from the server successfully');
      } catch (error) {
        console.error('Error deleting product from the server:', error);
        throw error;
      }
    } else {
      console.warn('Product does not have an _id, skipping server deletion');
    }
  };
  //================================================================================

  return (
    <div className={styles.container}>
      <div className={styles.maincontainer}>
        {isLoading && <LoadingOverlay />}
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              name="fromDate"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true // This ensures the label stays above the input value
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="toDate"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true // This ensures the label stays above the input value
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="query"
              label="Search by Customer Name or Contact"
              value={query}
              onChange={handleQueryChange}
              InputProps={{
                className: styles.invoiceinput,

              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdSearch />}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>

          <Grid item >
            {hasPermission(['admin', 'super-admin', 'store']) && (

              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#009999',
                  color: '#fff',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: '#01dcb8',
                  },
                }}
                startIcon={<MdOutlineAddchart />}
                onClick={() => setShowForm(true)}
              >
                Add Orders
              </Button>
            )}
          </Grid>
        </Grid>

        <Dialog
          open={showForm}
          onClose={() => { }}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            sx: {
              maxWidth: 'auto',
              maxHeight: 'auto',
              margin: 'auto',
              mt: 0, // Set the margin-top to 0
            },
          }}
          sx={{
            display: 'flex',
            alignItems: 'flex-start', // Align dialog to the top
            justifyContent: 'center',
          }}
        >
          <DialogContent>
            <div className={styles.invoicemaincontainer}>
              <div className={styles.invoicecontentContainer}>
                <h2 className={styles.invoiceheader}>Block Orders</h2>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ mt: -5, }}
                >

                  <Grid container spacing={2}>

                    <Grid item xs={2}>
                      <FormControl
                        fullWidth
                        error={!!state.errors.storeId}
                        disabled={userRole === 'store'}
                      >
                        <InputLabel sx={{ top: '-6px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>Store <span style={{ color: 'red' }}>*</span></InputLabel>
                        <Select
                          name="storeId"
                          value={userRole === 'store' ? userStore?.id : state.formValues.storeId}
                          onChange={handleInputChange}
                          label="Store"
                          className={styles.invoiceinput}
                          required={userRole !== 'store'}
                        >
                          {userRole === 'store' ? (
                            <MenuItem value={userStore?.id}>{userStore?.storeName}</MenuItem>
                          ) : (
                            [
                              <MenuItem key="none" value=""><em>None</em></MenuItem>,
                              ...stores.map((store) => (
                                <MenuItem key={store.id} value={store.id}>
                                  {store.storeName}
                                </MenuItem>
                              ))
                            ]
                          )}
                        </Select>
                        {state.errors.storeId && (
                          <FormHelperText>{state.errors.storeId}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        name="customerName"
                        label="Customer Name"
                        value={state.formValues.customerName}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        error={!!state.errors.customerName}
                        helperText={state.errors.customerName}
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                            // Hide the default asterisk and apply custom styles
                            '& .MuiFormLabel-asterisk': {
                              color: 'red', // Customize the color of the asterisk
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Text color
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        name="customerContact"
                        label="Customer Contact"
                        value={state.formValues.customerContact}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        inputProps={{
                          pattern: "[0-9]*",
                          inputMode: "numeric",
                          maxLength: 10,
                        }}
                        error={!!state.errors.customerContact}
                        helperText={state.errors.customerContact}
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                            // Hide the default asterisk and apply custom styles
                            '& .MuiFormLabel-asterisk': {
                              color: 'red', // Customize the color of the asterisk
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Text color
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        name="customerAddress"
                        label="Delivery Address"
                        value={state.formValues.customerAddress}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!state.errors.customerAddress}
                        helperText={state.errors.customerAddress}
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Adjust text color if needed
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <FormControl fullWidth error={!!state.errors.state}>
                        <InputLabel sx={{ top: '-6px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>State</InputLabel>
                        <Select
                          name="state"
                          value={state.formValues.state}
                          onChange={(e) => {
                            handleInputChange(e);
                            console.log('State updated:', e.target.value);
                          }}
                          label="State"
                          className={styles.invoiceinput}
                        >
                          <MenuItem value=""><em>None</em></MenuItem>
                          {Array.isArray(states) ? (
                            states.map((stateItem) => (
                              <MenuItem key={stateItem.id || stateItem._id} value={stateItem.state || stateItem.name}>
                                {stateItem.state || stateItem.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value=""><em>No states available</em></MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        name="city"
                        label="City"
                        value={state.formValues.city}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!state.errors.city}
                        helperText={state.errors.city}
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Adjust text color if needed
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        name="pinCode"
                        label="Pincode"
                        value={state.formValues.pinCode}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only numbers and limit to 6 digits
                          if (value === '' || /^[0-9]{0,6}$/.test(value)) {
                            handleInputChange(e);
                          }
                        }}
                        fullWidth
                        inputProps={{
                          pattern: "[0-9]{6}",
                          inputMode: "numeric",
                          maxLength: 6,
                        }}
                        error={!!state.errors.pinCode}
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Adjust text color if needed
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl fullWidth error={!!state.errors.modeOfPayment}>
                        <InputLabel sx={{ top: '-6px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>Mode Of Payment <span style={{ color: 'red' }}>*</span></InputLabel>
                        <Select
                          name="modeOfPayment"
                          label="Mode of Payment"
                          value={state.formValues.modeOfPayment}
                          onChange={handleInputChange}
                          className={styles.invoiceinput}
                        >
                          <MenuItem value=""><em>None</em></MenuItem>
                          <MenuItem value="CASH">Cash</MenuItem>
                          <MenuItem value="UPI">UPI</MenuItem>
                        </Select>
                        {state.errors.modeOfPayment && (
                          <FormHelperText>{state.errors.modeOfPayment}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>


                    <Grid item xs={2}>
                      <TextField
                        name="advancePayment"
                        label="Advance Payment"
                        value={formatIndianCurrency(state.formValues.advancePayment)}
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, ''); // Remove commas
                          if (value === '' || /^[0-9]{0,11}$/.test(value)) { // Allow up to 11 digits
                            handleInputChange({
                              target: {
                                name: 'advancePayment',
                                value: value
                              }
                            });
                          }
                        }}
                        fullWidth
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Adjust text color if needed
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <table className={styles.invoicetabledata}>
                  <thead>
                    <tr>
                      <th className={styles.invoiceTh}>Sr_No</th>
                      <th className={styles.invoiceTh}>Product Name</th>
                      <th className={styles.invoiceTh}>Category</th>
                      <th className={styles.invoiceTh}>Rate</th>
                      <th className={styles.invoiceTh}>Pieces</th>
                      <th className={styles.invoiceTh}>Amount</th>
                      <th className={styles.invoiceTh}>Action</th>
                    </tr>
                  </thead>


                  <tbody>
                    {products.map((product, index) => {
                      const selectedProductIds = products.map(p => p.id);
                      return (
                        <tr key={index}>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ddd' }}>
                            {index + 1}
                          </td>

                          <td className={styles.invoiceTd}>
                            <Autocomplete
                              options={productsData.filter(p => !selectedProductIds.includes(p.id) || p.id === product.id)} // Exclude already selected products
                              getOptionLabel={(option) => option.productName || ''}
                              value={productsData.find(p => p.id === product.id) || null}
                              onChange={(event, newValue) => {
                                // Set product ID, productName, and category when a product is selected
                                handleProductChange(index, 'id', newValue ? newValue.id : '');
                                handleProductChange(index, 'productName', newValue ? newValue.productName : '');
                                handleProductChange(index, 'category', newValue ? newValue.category : ''); // Add this line to set category
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Type to search..."
                                  sx={{
                                    width: '220px', // Adjust this value as needed
                                    '& .MuiOutlinedInput-root': {
                                      height: "30px",
                                      borderRadius: '0',
                                      '& fieldset': {
                                        borderColor: '#888',
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#333',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      width: "100%",
                                    }
                                  }}
                                />
                              )}
                              filterOptions={(options, { inputValue }) =>
                                options.filter(option =>
                                  option.productName.toLowerCase().startsWith(inputValue.toLowerCase())
                                )
                              }
                            />
                          </td>

                          <td className={styles.invoiceTd}>
                            <input
                              type="text"
                              value={product.category || ''}
                              disabled
                              className={styles.invoiceinputs}
                            />
                          </td>

                          <td className={styles.invoiceTd}>
                            <input
                              type="text"
                              value={formatIndianCurrency(product.rate)}
                              onChange={(e) => {
                                const value = e.target.value.replace(/,/g, ''); // Remove commas if any

                                // Allow only numbers and ensure it doesn't exceed 11 digits
                                if (value === '' || /^\d{1,11}(\.\d{0,2})?$/.test(value)) {
                                  handleProductChange(index, 'rate', value); // Call the change handler with valid input
                                }
                              }}
                              className={styles.invoiceinputs}
                              style={{ textAlign: 'right' }}
                            />
                          </td>

                          <td className={styles.invoiceTd}>
                            <input
                              value={product.pieces}
                              onChange={(e) => handleProductChange(index, 'pieces', e.target.value)}
                              className={styles.invoiceinputs}
                              style={{ textAlign: 'right' }}
                            />
                          </td>

                          <td className={styles.invoiceTd}>
                            <input
                              type="text"
                              value={formatIndianCurrency(product.amount)}
                              readOnly
                              className={styles.invoiceinputs}
                              style={{ textAlign: 'right' }}

                            />
                          </td>


                          <td className={styles.invoiceTd}>
                            <IconButton
                              onClick={(event) => showDeleteConfirmation(() => removeProductLine(product))}
                              className={styles.edeleteButton}>
                              <ClearIcon className={styles.invoiceicons} />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <Button sx={{
                  backgroundColor: '#009999',
                  color: '#fff',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: '#01dcb8',
                  },
                }} onClick={addProductLine}>+ Add Line</Button>

                <div className={styles.summary}>
                  <div>Sub Total: <span>₹{formatIndianCurrency(calculateTotals().subtotal)}</span></div>
                  <div>
                    Total Discount:
                    <input
                      value={formatIndianCurrency(totalDiscount)}
                      onChange={(e) => {
                        const value = e.target.value.replace(/,/g, ''); // Remove commas for parsing

                        // Allow only numbers and ensure it doesn't exceed 11 digits
                        if (value === '' || /^[0-9]{0,11}$/.test(value)) {
                          setTotalDiscount(parseFloat(value) || 0); // Update state if valid
                        }
                      }}

                      className={styles.invoiceinputs}
                      style={{ width: '80px', marginLeft: '10px', textAlign: 'right' }}
                    />

                  </div>
                  <div>Grand Total: <span>₹{formatIndianCurrency(calculateTotals().grandTotal)}</span></div>
                  <div>Advance Payment: <span>₹{formatIndianCurrency(state.formValues.advancePayment || '0')}</span></div>
                  <div>Balance: <span>₹{formatIndianCurrency(calculateTotals().balance)}</span></div>
                </div>

                <DialogActions sx={{ mt: 2, justifyContent: "flex-start" }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#009999',
                      color: '#fff',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#01dcb8',
                      },
                    }}
                    onClick={handleAddOrUpdateOrders}
                    startIcon={<AddIcon />}

                  >
                    {editIndex !== null ? 'Update Order' : 'Add Order'}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#e32e2e',
                      color:"fff",
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#ff0000',
                      },}}

                    onClick={() => {
                      resetForm();
                      resetForm();
                      setShowForm(false);
                      setEditIndex(null);
                    }}
                    startIcon={<CloseIcon />}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
          <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
          <Select
            labelId="rows-per-page-label"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            label="Rows per page"
          >
            {[10, 25, 50, 100].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className={styles.tablecontainer}>
          <TableContainer component={Paper}>
            <Table className={styles.storetable}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                  {userRole !== 'store' && (
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Store Name</TableCell>
                  )}
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Customer Name</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Customer Contact</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Delivery Address</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Date</TableCell>
                  {/* <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Status</TableCell> */}
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {blockList?.map((orders, index) => {
                  if (!orders) return null; // Skip rendering if orders is undefined
                  return (
                    <TableRow key={orders._id || index} style={{ backgroundColor: !orders.isActive ? '#ffcccc' : index % 2 === 0 ? 'white' : '#f0f0f0' }}>
                      <TableCell style={tableCellStyle}>{(index + 1 + (currentPage - 1) * itemsPerPage).toString().padStart(4, '0')}</TableCell>

                      {userRole !== 'store' && (
                        <TableCell style={tableCellStyle}>
                          {Array.isArray(orders.storeDetails) ? (
                            orders.storeDetails.map((store, idx) => (
                              <div key={idx}>{store.storeName}</div>
                            ))
                          ) : (
                            <div>{orders.storeDetails?.storeName}</div>  // Adjust this line based on how you want to handle non-array storeDetails
                          )}
                        </TableCell>)}

                      <TableCell style={tableCellStyle}>{orders.customerName}</TableCell>
                      <TableCell style={tableCellStyle}>{orders.customerContact}</TableCell>
                      <TableCell style={tableCellStyle}>{orders.customerAddress}</TableCell>
                      <TableCell style={tableCellStyle}>{orders.createdAt}</TableCell>
                      {/* <TableCell style={{
                      ...tableCellStyle,
                      backgroundColor: orders.status === 'Pending' ? 'yellow' : orders.status === 'Success' ? 'lightgreen' : 'white'
                    }}>
                      {orders.status || 'N/A'}
                    </TableCell> */}
                      <TableCell style={tableCellStyle}>
                        <IconButton onClick={() => handlePreviewOpen(orders._id)} >
                          <Preview className={styles.prew} />
                        </IconButton>
                        <IconButton onClick={() => handleEditOrders(blockList[index]._id, index + (currentPage - 1) * itemsPerPage)} >
                          <ModeEditOutline className={styles.editIcon} />
                        </IconButton>
                        <IconButton onClick={() => handleConfirmDeleteOpen(orders._id)} >
                          <Delete className={styles.editDelete} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>


            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#009999',
                  border: '1px solid #ddd',
                  margin: '0 4px',
                  borderRadius: '4px',
                },
                '& .Mui-selected': {
                  backgroundColor: '#009999',
                  color: 'white',
                },
              }}
            />
          </Box>
        </div>


        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {hasPermission(['admin', 'super-admin', 'store']) && (
            <MenuItem onClick={() => {
              if (blockList[selectedBlockIndex]) {
                handleEditOrders(blockList[selectedBlockIndex]._id, selectedBlockIndex);
              }
              handleMenuClose();
            }}>
              <MdEdit style={{ color: 'green', marginRight: '8px' }} /> Update
            </MenuItem>
          )}

          {hasPermission(['admin', 'super-admin', 'store']) && (
            <MenuItem onClick={() => {
              handleConfirmDeleteOpen(selectedBlockIndex);
              handleMenuClose();
            }}>
              <MdDeleteForever style={{ color: 'red', marginRight: '8px' }} /> Delete
            </MenuItem>
          )}

          <MenuItem onClick={() => {
            handlePreviewOpen(selectedBlockIndex);
          }}>
            <MdPreview style={{ color: 'chocolate', marginRight: '8px' }} /> Preview
          </MenuItem>

          {hasPermission(['admin', 'super-admin', 'store']) && (
            <MenuItem onClick={() => {
              handleStatusChange(selectedBlockIndex, 'Success');
              handleMenuClose();
            }}>
              <MdCheckCircle style={{ color: 'green', marginRight: '8px' }} /> Set Success
            </MenuItem>
          )}
        </Menu>

        <BlockInvoice
          blockData={blockData}
          previewOpen={previewOpen}
          handlePreviewClose={handlePreviewClose}
          handlePrint={handlePrint}
        />

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={errorMessage}

        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            Please fill in all fields correctly.
          </Alert>
        </Snackbar>

        <Dialog
          open={confirmDeleteOpen}
          onClose={handleConfirmDeleteClose}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this sale list?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleConfirmDeleteClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDeleteOrders} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* ====================================================alert======================================================= */}

        {alertInfo.open && (
          <Alert
            severity={alertInfo.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertInfo({ ...alertInfo, open: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
            sx={{
              position: 'fixed',
              top: 16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
            }}
          >
            {alertInfo.message}
          </Alert>
        )}
      </div>
    </div>
  );
};
export default BlockOrder;
