import React, { useReducer, useState, useEffect, } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Box, Grid, MenuItem,
  CircularProgress, FormControl, InputLabel, Select,
  DialogContentText,
  Menu
} from '@mui/material';
import { Delete as DeleteIcon, ModeEditOutline } from '@mui/icons-material';
import { MdSearch, } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import { Alert } from '@mui/lab';
import styles from '../store/store.module.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { MdEdit, MdDeleteForever } from 'react-icons/md';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const initialState = {
  formValues: {
    storeName: '',
    ownerName: '',
    mobileNumber: '',
    supportName: '',
    supportPhone: '',
    email: '',
    address: '',
    state: '',
    city: '',
    pinCode: "",
  },
  errors: {},
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUES':
      return {
        ...state,
        formValues: { ...state.formValues, ...action.payload },
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: { ...state.errors, ...action.payload },
      };
    case 'RESET_FORM':
      return {
        ...state,
        formValues: initialState.formValues,
        errors: {},
      };
    default:
      return state;
  }
};

const apiurl = process.env.REACT_APP_API_URL;

const Stores = () => {
  const [storeList, setStoreList] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [itemNo, setItemNo] = useState('');
  const itemsPerPage = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStoreIndex, setSelectedStoreIndex] = useState(null);
  const [states, setStates] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalStores, setTotalStores] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [isLoading, setIsLoading] = useState(false);


  //=====================loading=====================================================
  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <CircularProgress />
    </div>
  );

  const tableCellStyle = {
    padding: '4px',  // Ensure no extra padding affects height
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333'
  };

  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };

  //====================================================================================


  const [query, setQuery] = useState('');

  const fetchStores = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      let url = new URL(`${apiurl}store/table`);

      // Add parameters to the URL
      url.searchParams.append('page', currentPage);
      url.searchParams.append('limit', rowsPerPage);

      if (fromDate) {
        url.searchParams.append('fromDate', fromDate);
      }
      if (toDate) {
        url.searchParams.append('toDate', toDate);
      }
      if (query) {
        url.searchParams.append('query', query);
      }

      const response = await fetch(url.toString(), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch stores');
      }
      const data = await response.json();
      setStoreList(data.stores);
      setTotalStores(data.totalStores); // Assuming the API returns total count
      // Update total pages
      const totalPages = data.numberOfPages || Math.ceil(data.totalProduct / rowsPerPage);
      setTotalPages(totalPages);

    } catch (error) {
      console.error('Error fetching stores:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    fetchStores();
    // setQuery(''); // Reset the search input after searching
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    fetchStores(); // Call this to fetch data for the new page
  };

  useEffect(() => {
    fetchStores();
  }, [currentPage, rowsPerPage]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobileNumber") {
      const isNumeric = /^\d*$/.test(value);
      if (!isNumeric) return;
    }
    dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: value } });
    dispatch({ type: 'SET_ERRORS', payload: { [name]: '' } });
  };



  const validateForm = () => {
    const { storeName, ownerName, mobileNumber, email, address, state: stateValue, city, pinCode } = state.formValues;
    const newErrors = {};

    if (!storeName) newErrors.storeName = 'Store Name is required';
    if (!ownerName) newErrors.ownerName = 'Owner Name is required';
    if (!mobileNumber) newErrors.mobileNumber = 'Contact is required';
    if (!email) newErrors.email = 'Email is required';
    if (!address) newErrors.address = 'Address is required';
    if (!stateValue) newErrors.state = 'State is required';
    if (!city) newErrors.city = 'City is required';
    if (!pinCode) newErrors.pinCode = 'Pincode is required';
    else if (!/^\d{6}$/.test(pinCode)) newErrors.pinCode = 'Pincode must be 6 digits';

    dispatch({ type: 'SET_ERRORS', payload: newErrors });
    return Object.keys(newErrors).length === 0;
  };

  const handleAddOrUpdateStore = async (e) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    e.preventDefault();

    if (!validateForm()) {
      setErrorSnackbarOpen(true);
      setIsLoading(false);
      return;
    }
    const formData = {
      storeName: state.formValues.storeName,
      ownerName: state.formValues.ownerName,
      mobileNumber: state.formValues.mobileNumber.toString(), // Explicitly convert to string
      supportName: state.formValues.supportName,
      supportPhone: state.formValues.supportPhone.toString(),
      email: state.formValues.email,
      address: state.formValues.address,
      state: state.formValues.state,
      city: state.formValues.city,
      pinCode: state.formValues.pinCode.toString(),
    };

    try {
      let response;
      if (editIndex !== null) {
        response = await fetch(`${apiurl}store/${storeId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData),
        });
      } else {
        response = await fetch(`${apiurl}store`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData),

        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to add/update store: ${errorData.message || response.statusText}`);
      }

      const updatedStore = await response.json();

      setStoreList(prevList => {
        if (editIndex !== null) {
          // Update existing store
          return prevList.map(store =>
            store._id === updatedStore._id ? updatedStore : store
          );
        } else {
          // Add new store
          return [updatedStore, ...prevList];
        }
      });


      dispatch({ type: 'RESET_FORM' });
      setShowForm(false);
      setEditIndex(null);
      setStoreId(null);  // Reset staffId after update


      fetchStores();
      showAlert(editIndex !== null ? 'Store updated successfully' : 'Store added successfully', 'success');

    } catch (error) {
      console.error('Error adding/updating store:', error);
      showAlert(`Failed to ${editIndex !== null ? 'update' : 'add'} store: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };


  const handleConfirmDeleteOpen = (storeId) => {
    setDeleteIndex(storeId);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setDeleteIndex(null);
  };


  const handleDeleteStore = async () => {
    const token = localStorage.getItem('token');

    try {
      if (!deleteIndex) {
        throw new Error('Invalid product selected for deletion');
      }


      const response = await fetch(`${apiurl}store/${deleteIndex}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete store');
      }

      console.log('Store deleted successfully');

      // Update the Store list
      setStoreList((prevStoreList) => {
        const updatedList = prevStoreList.filter((store) => store._id !== deleteIndex);

        // If the current page is now empty and it's not the first page, go to the previous page
        if (updatedList.length === 0 && currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }

        return updatedList;
      });

      // Fetch the updated Store list
      fetchStores();

      showAlert('Store deleted successfully', 'success');

    } catch (error) {
      console.error('Error deleting Store:', error);
      showAlert(`Failed to delete Store: ${error.message}`, 'error');
    } finally {
      setConfirmDeleteOpen(false);
      setDeleteIndex(null);
      setIsLoading(false);
    }
  };




  const handleEditStore = (_id, index) => {
    setStoreId(_id);

    const actualIndex = (currentPage - 1) * itemsPerPage + index;
    const storeToEdit = storeList.find(store => store._id === _id);

    if (!storeToEdit) {
      console.error('store not found:', _id, index, actualIndex);
      showAlert('Error: store not found', 'error');
      return;
    }

    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        storeName: storeToEdit.storeName || '',
        ownerName: storeToEdit.ownerName || '',
        mobileNumber: storeToEdit.mobileNumber || '',
        supportName: storeToEdit.supportName || '',
        supportPhone: storeToEdit.supportPhone || '',
        email: storeToEdit.email || '',
        address: storeToEdit.address || '',
        state: storeToEdit.state || '',
        city: storeToEdit.city || '',
        pinCode: storeToEdit.pinCode || '',
      }
    });
    setShowForm(true);
    setEditIndex(storeList.findIndex(store => store._id === _id));
  };


  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };



  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedStoreIndex(null);
  };


  //states api fetch ----
  const fetchStates = async () => {
    const token = localStorage.getItem('token');

    try {
      console.log('Fetching states...');
      const response = await fetch(`${apiurl}state`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch state');
      }
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        console.log('First state item:', data[0]);
      } else {
        console.log('Data is not an array or is empty');
      }
      setStates(data);
    } catch (error) {
      console.error('Error fetching state:', error);
    }
  };

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };

  useEffect(() => {
    fetchStates();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer} >
        {isLoading && <LoadingOverlay />}
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              name="fromDate"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true // This ensures the label stays above the input value
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="toDate"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true // This ensures the label stays above the input value
              }}

            />
          </Grid>
          <Grid item>
            <TextField
              name="query"
              label="Search by Store or Owner Name"
              value={query}
              onChange={handleQueryChange}
              InputProps={{
                className: styles.invoiceinput,

              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <Button
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              variant="contained"
              startIcon={<MdSearch />}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              variant="contained"
              startIcon={<AddBusinessIcon />}
              onClick={() => setShowForm(true)}
            >
              Add Stores
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              label="Rows per page"
            >
              {[10, 25, 50, 100].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <div className={styles.tablecon}>
          <TableContainer component={Paper}>
            <Table className={styles.storetable}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>

                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Store Name</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Owner Name</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Contact No</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Email</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Address</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>State</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>City</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Pincode</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Date</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {storeList?.map((store, index) => (
                  <TableRow
                    key={store._id}
                    style={{ backgroundColor: !store.isActive ? '#ffcccc' : index % 2 === 0 ? 'white' : '#f0f0f0' }}>
                    <TableCell style={tableCellStyle}>{(index + 1 + (currentPage - 1) * itemsPerPage).toString().padStart(4, '0')}</TableCell>
                    <TableCell style={tableCellStyle}>{store.storeName}</TableCell>
                    <TableCell style={tableCellStyle}>{store.ownerName}</TableCell>
                    <TableCell style={tableCellStyle}>{store.mobileNumber}</TableCell>
                    <TableCell style={tableCellStyle}>{store.email}</TableCell>
                    <TableCell style={tableCellStyle}>{store.address}</TableCell>
                    <TableCell style={tableCellStyle}>{store.state}</TableCell>
                    <TableCell style={tableCellStyle}>{store.city}</TableCell>
                    <TableCell style={tableCellStyle}>{store.pinCode}</TableCell>
                    <TableCell style={tableCellStyle}>{store.createdAt}</TableCell>
                    <TableCell style={tableCellStyle}>
                      <IconButton onClick={() => handleEditStore(storeList[index]._id, index + (currentPage - 1) * itemsPerPage)} >
                        <ModeEditOutline sx={{color: "#1a9f53"}} />
                      </IconButton>
                      <IconButton onClick={() => handleConfirmDeleteOpen(store._id)} >
                        <DeleteIcon sx={{color:"#ff3b59"}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>

            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#009999',
                  border: '1px solid #ddd',
                  margin: '0 4px',
                  borderRadius: '4px',
                },
                '& .Mui-selected': {
                  backgroundColor: '#009999',
                  color: 'white',
                },
              }}
            />
          </Box>
        </div>

        {/* ====================================================alert======================================================= */}
        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            Please fill out all required fields.
          </Alert>
        </Snackbar>

        <Dialog
          open={showForm}
          onClose={() => {}}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            sx: {
              maxWidth: 'auto',
              maxHeight: 'auto',
              margin: 'auto',
              mt: 0, // Set the margin-top to 0
            },
          }}
          sx={{
            display: 'flex',
            alignItems: 'flex-start', // Align dialog to the top
            justifyContent: 'center',
          }}
        >
          <DialogTitle>{editIndex !== null ? 'Edit Store' : 'Add Store'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editIndex !== null ? 'Edit the details of the store.' : 'Fill in the details of the new store.'}
            </DialogContentText>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleAddOrUpdateStore}
              sx={{ mt: 2, }}
            >

              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    name="storeName"
                    label="Store Name"
                    value={state.formValues.storeName}
                    onChange={handleInputChange}
                    error={!!state.errors.storeName}
                    helperText={state.errors.storeName}
                    required
                    fullWidth
                    sx={{
                      '& .MuiInputLabel-root': {
                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                        // Hide the default asterisk and apply custom styles
                        '& .MuiFormLabel-asterisk': {
                          color: 'red', // Customize the color of the asterisk
                        },
                      },
                      '& .MuiInputBase-root': {
                        color: '#000', // Text color
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    name="ownerName"
                    label="Owner Name"
                    value={state.formValues.ownerName}
                    onChange={handleInputChange}
                    error={!!state.errors.ownerName}
                    helperText={state.errors.ownerName}
                    required
                    fullWidth
                    sx={{
                      '& .MuiInputLabel-root': {
                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                        // Hide the default asterisk and apply custom styles
                        '& .MuiFormLabel-asterisk': {
                          color: 'red', // Customize the color of the asterisk
                        },
                      },
                      '& .MuiInputBase-root': {
                        color: '#000', // Text color
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    name="mobileNumber"
                    label="Contact No"
                    value={state.formValues.mobileNumber}
                    onChange={handleInputChange}
                    error={!!state.errors.mobileNumber}
                    helperText={state.errors.mobileNumber}
                    required
                    fullWidth
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      maxLength: 10,
                    }}
                    sx={{
                      '& .MuiInputLabel-root': {
                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                        // Hide the default asterisk and apply custom styles
                        '& .MuiFormLabel-asterisk': {
                          color: 'red', // Customize the color of the asterisk
                        },
                      },
                      '& .MuiInputBase-root': {
                        color: '#000', // Text color
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="supportName"
                    label="Support Name"
                    value={state.formValues.supportName}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{
                      '& .MuiInputLabel-root': {
                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                        // Hide the default asterisk and apply custom styles
                        '& .MuiFormLabel-asterisk': {
                          color: 'red', // Customize the color of the asterisk
                        },
                      },
                      '& .MuiInputBase-root': {
                        color: '#000', // Text color
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="supportPhone"
                    label="Contact No"
                    value={state.formValues.supportPhone}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                      maxLength: 10,
                    }}
                    sx={{
                      '& .MuiInputLabel-root': {
                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                        // Hide the default asterisk and apply custom styles
                        '& .MuiFormLabel-asterisk': {
                          color: 'red', // Customize the color of the asterisk
                        },
                      },
                      '& .MuiInputBase-root': {
                        color: '#000', // Text color
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="email"
                    label="Email"
                    type="email"
                    value={state.formValues.email}
                    onChange={handleInputChange}
                    error={!!state.errors.email}
                    helperText={state.errors.email}
                    required={{ color: "red" }}
                    fullWidth
                    sx={{
                      '& .MuiInputLabel-root': {
                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                        // Hide the default asterisk and apply custom styles
                        '& .MuiFormLabel-asterisk': {
                          color: 'red', // Customize the color of the asterisk
                        },
                      },
                      '& .MuiInputBase-root': {
                        color: '#000', // Text color
                      },
                    }}
                  />
                </Grid>


                <Grid item xs={3}>
                  <TextField
                    name="address"
                    label="Address"
                    value={state.formValues.address}
                    onChange={handleInputChange}
                    required={{ color: "red" }}
                    error={!!state.errors.address}
                    helperText={state.errors.address}
                    fullWidth
                    sx={{
                      '& .MuiInputLabel-root': {

                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                        // Hide the default asterisk and apply custom styles
                        '& .MuiFormLabel-asterisk': {
                          color: 'red', // Customize the color of the asterisk
                        },
                      },
                      '& .MuiInputBase-root': {
                        color: '#000', // Text color
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControl fullWidth error={!!state.errors.state}>
                    <InputLabel >State</InputLabel>
                    <Select
                      name="state"
                      value={state.formValues.state}
                      onChange={(e) => {
                        handleInputChange(e);
                        console.log('State updated:', e.target.value);
                      }}
                      label="State"
                      required={{ color: "red" }}
                      error={!!state.errors.state}
                      helperText={state.errors.state}
                      sx={{
                        '& .MuiInputLabel-root': {
                          color: '#555',
                          '&.Mui-focused': {
                            color: '#009999',
                          },
                          // Hide the default asterisk and apply custom styles
                          '& .MuiFormLabel-asterisk': {
                            color: 'red', // Customize the color of the asterisk
                          },
                        },
                        '& .MuiInputBase-root': {
                          color: '#000', // Text color
                        },
                      }}
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      {Array.isArray(states) ? (
                        states.map((stateItem) => (
                          <MenuItem key={stateItem.id || stateItem._id} value={stateItem.state || stateItem.name}>
                            {stateItem.state || stateItem.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value=""><em>No states available</em></MenuItem>
                      )}
                    </Select>

                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    name="city"
                    label="City"
                    value={state.formValues.city}
                    onChange={handleInputChange}
                    required={{ color: "red" }}
                    error={!!state.errors.city}
                    helperText={state.errors.city}
                    fullWidth
                    sx={{
                      '& .MuiInputLabel-root': {

                        color: '#555',
                        '&.Mui-focused': {
                          color: '#009999',
                        },
                        // Hide the default asterisk and apply custom styles
                        '& .MuiFormLabel-asterisk': {
                          color: 'red', // Customize the color of the asterisk
                        },
                      },
                      '& .MuiInputBase-root': {
                        color: '#000', // Text color
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="pinCode"
                    label="Pincode"
                    value={state.formValues.pinCode}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow typing and limit to numbers only
                      if (/^[0-9]*$/.test(value) && value.length <= 6) {
                        handleInputChange(e);
                      }
                    }}
                    error={!!state.errors.pinCode}
                    helperText={state.errors.pinCode}
                    required
                    fullWidth
                    inputProps={{
                      inputMode: "numeric",
                      maxLength: 6,
                    }}
                   
                  />
                </Grid>

              </Grid>
              <DialogActions sx={{ mt: 2, justifyContent: "flex-start" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#009999',
                    color: '#fff',
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#01dcb8',
                    },
                  }}
                  type="submit"
                  startIcon={<AddIcon />}

                >
                  {editIndex !== null ? 'Update Store' : 'Add Store'}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#e32e2e',
                    color:"fff",
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#ff0000',
                    },}}

                  onClick={() => {
                    dispatch({ type: 'RESET_FORM' });
                    setShowForm(false);
                    setEditIndex(null);
                  }}
                  startIcon={<CloseIcon />}

                >
                  Cancel
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => {
            handleEditStore(storeList[selectedStoreIndex]._id, selectedStoreIndex);
            handleMenuClose();
          }}>
            <MdEdit style={{ color: 'green', marginRight: '8px' }} /> Update
          </MenuItem>

          <MenuItem onClick={() => {
            handleConfirmDeleteOpen(selectedStoreIndex);
            handleMenuClose();
          }}>
            <MdDeleteForever style={{ color: 'red', marginRight: '8px' }} /> Delete
          </MenuItem>

        </Menu>

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            Please fill out all required fields.
          </Alert>
        </Snackbar>

        <Dialog
          open={confirmDeleteOpen}
          onClose={handleConfirmDeleteClose}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this staff member ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleConfirmDeleteClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDeleteStore} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* ====================================================alert======================================================= */}

        {alertInfo.open && (
          <Alert
            severity={alertInfo.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertInfo({ ...alertInfo, open: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
            sx={{
              position: 'fixed',
              top: 16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
            }}
          >
            {alertInfo.message}
          </Alert>
        )}

      </div>
    </div>
  );
};

export default Stores;