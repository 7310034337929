import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaStore, FaChartLine } from 'react-icons/fa';
import { GiBuyCard, GiExpense } from "react-icons/gi";
import { MdOutlineInventory, MdOutlineProductionQuantityLimits, MdKeyboardArrowDown, MdDisplaySettings } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi2";
import { RiDiscountPercentFill } from "react-icons/ri";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { SiAdblock } from "react-icons/si";
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { FaTruckFast } from "react-icons/fa6";
import styles from "../Navbar/navbar.module.css";

import Dashboard from '../dashboard';
import Inventory from '../../tabfiles/inventory/inventory';
import Product from '../../tabfiles/Product/product';
import Display from '../../tabfiles/displayProduct/display';
import TransferHistory from '../../tabfiles/transferHistory/transferHistory';
import DailyExpensives from '../../tabfiles/dailyexpensive/dailyexpensives';
import Sales from '../../tabfiles/sellitem/sales';
import BlockOrder from '../../tabfiles/blockOrder/blockOrder';
import QuotationFormat from '../../tabfiles/quotation/quotationFormat';
import PurchaseFormat from '../../tabfiles/purchaseFormat/purchaseFormat';
import Stores from '../../tabfiles/store/store';
import Staff from '../../tabfiles/staff/staff';
// import Profile from "../../tabfiles/profile/profile";

const drawerWidth = 210;
const apiurl = process.env.REACT_APP_API_URL;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: "#333"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: "#333",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  height: 60, // Adjust the height here as needed
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  })
);

const menuItems = [
  { text: 'Dashboard', icon: <TbLayoutDashboardFilled />, path: '/dashboard', component: Dashboard, roleName: ['admin', 'store', 'super-admin'] },
  { text: 'Inventory', icon: <MdOutlineInventory />, path: '/inventory', component: Inventory, roleName: ['admin', 'store', 'staff', 'super-admin'] },
  { text: 'Products', icon: <MdOutlineProductionQuantityLimits />, path: '/products', component: Product, roleName: ['admin', 'super-admin'] },
  { text: 'Display Product', icon: <MdDisplaySettings />, path: '/display-product', component: Display, roleName: ['admin', 'store', 'super-admin', 'staff'] },
  { text: 'Transfer History', icon: <FaTruckFast />, path: '/transfer-history', component: TransferHistory, roleName: ['admin', 'store', 'super-admin'] },
  { text: 'Daily Expenses', icon: <GiExpense />, path: '/daily-expenses', component: DailyExpensives, roleName: ['admin', 'store', 'super-admin'] },
  { text: 'Sales', icon: <FaChartLine />, path: '/sales', component: Sales, roleName: ['admin', 'store', 'super-admin'] },
  { text: 'Block Orders', icon: <SiAdblock />, path: '/block-orders', component: BlockOrder, roleName: ['admin', 'store', 'super-admin', 'staff'] },
  { text: 'Quotations', icon: <RiDiscountPercentFill />, path: '/quotations', component: QuotationFormat, roleName: ['admin', 'store', 'super-admin'] },
  { text: 'Purchase', icon: <GiBuyCard />, path: '/purchase', component: PurchaseFormat, roleName: ['admin', 'super-admin'] },
  { text: 'Stores', icon: <FaStore />, path: '/stores', component: Stores, roleName: ['admin', 'super-admin'] },
  { text: 'Staffs', icon: <HiUserGroup />, path: '/staffs', component: Staff, roleName: ['admin', 'super-admin'] },
  // { text: 'Profile', icon: <MdOutlineInventory />, path: '/profile', component: Profile, roleName: ['admin', 'store', 'staff', 'super-admin'] },
];

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [menudata, setMenudata] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [userRole, setUserRole] = useState("");
  const [userData, setUserData] = useState({ name: "", roleName: "" });


  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    // Redirect to login page
    navigate('/');
  };

  useEffect(() => {
    const preventBackNavigation = () => {
      window.history.pushState(null, null, window.location.href);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', preventBackNavigation);

    return () => {
      window.removeEventListener('popstate', preventBackNavigation);
    };
  }, []);

  useEffect(() => {
    const storedRole = localStorage.getItem("roleName");
    // console.log("Retrieved role from localStorage:", storedRole);
    setUserRole(storedRole || "");
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${apiurl}user/loggeduser`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUserData(data.user);
        if (data.user && data.user.roleName) {
          // console.log("Setting role from API response:", data.user.roleName);
          localStorage.setItem("roleName", data.user.roleName);
          setUserRole(data.user.roleName);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const effectiveRole = userRole || userData.roleName;
  // console.log("Effective role:", effectiveRole);

  const filteredMenuItems = menuItems.filter(item => {
    // console.log(`Checking item: ${item.text}, Effective role: ${effectiveRole}`);
    return item.roleName.includes(effectiveRole.toLowerCase());
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" elevation={5} sx={{ backgroundColor: "#fff", color: "black" }}>
        <Toolbar>
          <div className={styles.title} style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/fl.png" width={45} height={45} style={{ verticalAlign: 'middle', marginRight: '10px' }} alt="" />
            <h5 variant="body2" color="text.secondary">
              {userData.storeName}
            </h5>
          </div>

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '4px',
              },
              padding: '4px 8px',
            }}
            onClick={handleClick}
          >
            <Avatar sx={{ width: 32, height: 32, marginRight: 1, bgcolor: '#009999', color: 'white', }}>{userData.name?.[0]}</Avatar>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: 1 }}>
              <Typography variant="subtitle1">{userData.name}</Typography>
            </Box>
            <MdKeyboardArrowDown />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                minWidth: '200px',
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem sx={{ justifyContent: 'center' }}>
              <Avatar
                sx={{
                  width: 56, height: 56, bgcolor: '#009999', color: 'white', fontWeight: 'bold', fontSize: '1.5rem',
                }}> {userData.name?.[0]}
              </Avatar>
            </MenuItem>
            <MenuItem>
              <Typography variant="subtitle1">{userData.name}</Typography>
            </MenuItem>
            <MenuItem>
              <Typography variant="body2" color="text.secondary">{userData.roleName}</Typography>
            </MenuItem>
            <MenuItem>
              <Typography variant="body2" color="text.secondary">{userData.storeName}</Typography>
            </MenuItem>
            <MenuItem>
              <Typography variant="body2" color="text.secondary">{userData.email}</Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout} sx={{borderRadius:'20px', gap:'10px',color:'black','&:hover':{backgroundColor:'red',color:"#fff"}}}>
                <Logout
                  fontSize="small" />
              Logout
            </MenuItem>

          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton>
            {theme.direction === 'rtl'}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {filteredMenuItems.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ display: 'block', mb: 1 }}
              onClick={() => {
                setMenudata(item.text);
                navigate(item.path);
              }}            >
              <ListItemButton
                sx={{
                  minHeight: 25,
                  justifyContent: 'initial',
                  px: 2.5,
                  backgroundColor: location.pathname === item.path ? '#009999' : "#333",
                  color: location.pathname === item.path ? '#fff' : '#fff',
                  '&:hover': {
                    backgroundColor: '#009999',
                    color: '#fff',
                  },
                }}
                disableRipple
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center',
                    color: '#fff',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} sx={{ opacity: 1 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Routes>
          {filteredMenuItems.map((item, index) => (
            <Route key={index} path={item.path} element={<item.component />} />
          ))}

        </Routes>
      </Box>
    </Box>
  );
}