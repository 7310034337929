import React, { useReducer, useState, useEffect } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, Snackbar, Box, Grid, Select, MenuItem, FormControl, InputLabel, Menu,
} from '@mui/material'
import { MdEdit, MdDeleteForever, MdOutlineAddchart, MdSearch, } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import styles from '../Product/product.module.css';
import DialogProduct from '../../DIALOG/dialogproduct';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { Delete, ModeEditOutline } from '@mui/icons-material';

const initialState = {
  formValues: {
    productName: '',
    image: '',
    category: '',
  },
  errors: {},
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUES':
      return { ...state, formValues: { ...state.formValues, ...action.payload } };
    case 'RESET_FORM':
      return { ...state, formValues: initialState.formValues, errors: {} };
    case 'SET_ERRORS':
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

const apiurl = process.env.REACT_APP_API_URL;

const Product = () => {
  const [productList, setProductList] = useState([]);
  const [productId, setProductId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileName, setImageFileName] = useState('');
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [query, setQuery] = useState('');
  const itemsPerPage = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  const [isActiveStatus, setIsActiveStatus] = useState(true); // default to true or as needed

  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalStores, setTotalStores] = useState(0);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  ///--------------------loading===================================================================================
  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <CircularProgress />
    </div>
  );

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };

  const handleImageClick = (imageSrc) => {
    setEnlargedImage(imageSrc);
  };
  const handleCloseEnlargedImage = () => {
    setEnlargedImage(null);
  };

  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };

  //====================================================================================

  const tableCellStyle = {
    padding: '4px',  // Ensure no extra padding affects height
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333'
  };

  const fetchProducts = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      let url = new URL(`${apiurl}product`);

      // Add parameters to the URL
      url.searchParams.append('page', currentPage);
      url.searchParams.append('limit', rowsPerPage);

      if (fromDate) {
        url.searchParams.append('fromDate', fromDate);
      }
      if (toDate) {
        url.searchParams.append('toDate', toDate);
      }
      if (query) {
        url.searchParams.append('query', query);
      }

      const response = await fetch(url.toString(), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }

      const data = await response.json();

      setProductList(data.products);
      setTotalStores(data.totalStores)

      // Update total pages
      const totalPages = data.numberOfPages || Math.ceil(data.totalProduct / rowsPerPage);
      setTotalPages(totalPages);


      localStorage.setItem('product', data?.totalProduct);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    fetchProducts();
    // setQuery('');
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    fetchProducts(); // Call this to fetch data for the new page
  };

  useEffect(() => {

    fetchProducts();
  }, [currentPage, rowsPerPage]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "category") {
      const selectedCategory = categories.find(cat => cat.name === value);
      if (selectedCategory) {
        dispatch({ 
          type: 'SET_FORM_VALUES', 
          payload: { 
            [name]: value,
            categoryId: selectedCategory._id // Store the categoryId as well
          } 
        });
      }
    } else {
      dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: value } });
    }
    dispatch({ type: 'SET_ERRORS', payload: { [name]: '' } });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImageFileName(file.name);
    }
  };

  const validateForm = () => {
    const { productName, category, } = state.formValues;
    const newErrors = {};
    console.log(state.formValues)
    if (!productName) newErrors.productName = 'Product Name is required';
    if (!category) newErrors.category = 'Category is required';

    dispatch({ type: 'SET_ERRORS', payload: newErrors });
    return Object.keys(newErrors).length === 0;
  };

  const handleAddOrUpdateProduct = async (e) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    if (!validateForm()) {
      setErrorSnackbarOpen(true);
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('productName', state.formValues.productName);

    // Find the category object based on the selected category name
    const selectedCategory = categories.find(cat => cat.name === state.formValues.category);
    if (selectedCategory) {
      formData.append('categoryId', selectedCategory.categoryId); // Append the categoryId instead of name
    } else {
      console.error('Selected category not found');
      setIsLoading(false);
      showAlert('Invalid category selected', 'error');
      return;
    }

    if (imageFile) {
      formData.append('image', imageFile);
    } else {
      formData.append('image', state.formValues.image);
    }

    // Log the form data
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      let response;
      if (editIndex !== null) {
        // Update existing product
        response = await fetch(`${apiurl}product/${productId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData,
        });
      } else {
        // Add new product
        response = await fetch(`${apiurl}product`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData,
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to ${editIndex !== null ? 'update' : 'add'} product: ${errorData.message || response.statusText}`);
      }

      const updatedProduct = await response.json();

      setProductList(prevList => {
        if (editIndex !== null) {
          // Update existing product
          return prevList.map(product =>
      product._id === updatedProduct._id ? {
        ...product,
        ...updatedProduct,
        categoryName: categories.find(cat => cat.categoryId === updatedProduct.categoryId)?.name
      } : product
    );
        } else {
          // Add new product
          showAlert('Product added successfully', 'success');
          return [{
            ...updatedProduct,
            categoryName: categories.find(cat => cat.categoryId === updatedProduct.categoryId)?.name
          }, ...prevList];
        }
      });

      // Reset form and close dialog
      dispatch({ type: 'RESET_FORM' });
      setImageFile(null);
      setImageFileName('');
      setShowForm(false);
      setEditIndex(null);
      setProductId(null);

      // Fetch updated product list
      fetchProducts();

    } catch (error) {
      showAlert(`Failed to ${editIndex !== null ? 'update' : 'add'} product: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDeleteOpen = (productId) => {
    setDeleteIndex(productId);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setDeleteIndex(null);
  };

  const handleDeleteProduct = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      if (!deleteIndex) {
        throw new Error('Invalid product selected for deletion');
      }

      const response = await fetch(`${apiurl}product/${deleteIndex}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete Product');
      }

      console.log('Product deleted successfully');
      
      // Update the product list
      setProductList((prevProductList) => {
        const updatedList = prevProductList.filter((product) => product._id !== deleteIndex);
        
        // If the current page is now empty and it's not the first page, go to the previous page
        if (updatedList.length === 0 && currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }
        
        return updatedList;
      });

      // Fetch the updated product list
      fetchProducts();

      showAlert('Product deleted successfully', 'success');

    } catch (error) {
      console.error('Error deleting Product:', error);
      showAlert(`Failed to delete product: ${error.message}`, 'error');
    } finally {
      setConfirmDeleteOpen(false);
      setDeleteIndex(null);
      setIsLoading(false);
    }
  };

  const handleUpdateProduct = (_id, index) => {
    setProductId(_id);
    const actualIndex = (currentPage - 1) * itemsPerPage + index;
    // Use the _id directly to find the product instead of relying on the newly set state
    const productToEdit = productList.find(product => product._id === _id);

    if (!productToEdit) {
      console.error('Product not found:', _id, index, actualIndex);
      showAlert('Error: Product not found', 'error');
      return;
    }

    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        productName: productToEdit.productName || '',
        category: productToEdit.categoryName || '', // Make sure this is the correct field name
        image: productToEdit.image || '',

      }
    });
    setImageFileName(productToEdit.image ? productToEdit.image.split('/').pop() : '');
    setShowForm(true);
    setEditIndex(productList.findIndex(product => product._id === productId));
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedProductIndex(null);
  };

 
  return (
    <div className={styles.container}>
      <div className={styles.productmaincontainer}>
        {isLoading && <LoadingOverlay />}
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              name="fromDate"
              type="date"
              label="From Date"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true // This ensures the label stays above the input value
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="toDate"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true // This ensures the label stays above the input value
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="query"
              label="Search by Product/Category Name"
              value={query}
              onChange={handleQueryChange}
              InputProps={{
                className: styles.invoiceinput,

              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdSearch />}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>


          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdOutlineAddchart />}
              onClick={() => setShowForm(true)}
            >
              Add Product
            </Button>
          </Grid>
        </Grid>

        <DialogProduct
          showForm={showForm}
          onClose={() => {
            setShowForm(false);
            setEditIndex(null);
            dispatch({ type: 'RESET_FORM' });
          }}
          onSubmit={handleAddOrUpdateProduct}
          editIndex={editIndex}
          dispatch={dispatch}  // Add this line

          state={state}
          imageFileName={imageFileName}
          handleInputChange={handleInputChange}
          handleImageChange={handleImageChange}
          categories={categories}
          units={units}
          // Add these lines:
          selectedCategory={state.formValues.category}
          selectedUnit={state.formValues.unit}
          setCategories={setCategories}
          setUnits={setUnits}
          Snackbar={Snackbar}
          showAlert={showAlert}
          Alert={Alert}
        />
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
          <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
          <Select
            labelId="rows-per-page-label"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            label="Rows per page"
          >
            {[10, 25, 50, 100].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className={styles.tablecontainer}>
          <TableContainer component={Paper}>
            <Table className={styles.storetable}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Image</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Product Name</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Category</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Date</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productList?.map((product, index) => (
                  <TableRow
                    key={product._id}
                    style={{ backgroundColor: !product.isActive ? '#ffcccc' : index % 2 === 0 ? 'white' : '#f0f0f0' }}>
                    <TableCell style={tableCellStyle}>
                      {(index + 1 + (currentPage - 1) * itemsPerPage).toString().padStart(4, '0')}
                    </TableCell>
                    <TableCell style={tableCellStyle}>
                      <img
                        src={product.image}
                        alt={product.productName}
                        style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                        onClick={() => handleImageClick(product.image)}
                      />
                    </TableCell>

                    <TableCell style={tableCellStyle}>{product.productName} </TableCell>

                    <TableCell style={tableCellStyle}>{product.categoryName}</TableCell>
                    <TableCell style={tableCellStyle}>{product.createdAt}</TableCell>

                    <TableCell style={tableCellStyle}>
                      <IconButton onClick={() => handleUpdateProduct(productList[index]._id, index + (currentPage - 1) * itemsPerPage, product.isActive)} >
                        <ModeEditOutline className={styles.editIcon} />
                      </IconButton>
                      <IconButton onClick={() => handleConfirmDeleteOpen(product._id)}>
                      <Delete className={styles.editDelete} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

            </Table>
          </TableContainer>


          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>

            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}

              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#009999',
                  border: '1px solid #ddd',
                  margin: '0 4px',
                  borderRadius: '4px',
                },
                '& .Mui-selected': {
                  backgroundColor: '#009999',
                  color: 'white',
                },
              }}
            />
          </Box>
        </div>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => {
            handleUpdateProduct(productList[selectedProductIndex]._id, selectedProductIndex);
            handleMenuClose();
          }}>
            <MdEdit style={{ color: 'green', marginRight: '8px' }} /> Update
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleConfirmDeleteOpen(selectedProductIndex);
              handleMenuClose();
            }}
            disabled={!isActiveStatus}
          >
            <MdDeleteForever style={{ color: 'red', marginRight: '8px' }} /> Delete
          </MenuItem>

        </Menu>

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={4000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            Please fill in all fields correctly.
          </Alert>
        </Snackbar>

        <Dialog
          open={confirmDeleteOpen}
          onClose={handleConfirmDeleteClose}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this product?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleConfirmDeleteClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDeleteProduct} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* ======================================image inlarge=========================================================================================== */}

        <Dialog open={enlargedImage !== null} onClose={handleCloseEnlargedImage}>
          <DialogContent sx={{padding:0}}>
            <img src={enlargedImage} alt="Enlarged product" style={{ width: '100%', height: 'auto' }} />
          </DialogContent>
        </Dialog>

        {/* ====================================================alert======================================================= */}

        {alertInfo.open && (
          <Alert
            severity={alertInfo.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertInfo({ ...alertInfo, open: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
            sx={{
              position: 'fixed',
              top: 16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
            }}
          >
            {alertInfo.message}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default Product;