import React, { useReducer, useState, useEffect,useCallback } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar,
  Box, Grid, Select, MenuItem, FormControl, InputLabel, Menu, Divider,
  FormHelperText
} from '@mui/material';
import { MdPreview, MdEdit, MdDeleteForever, MdAdd, MdOutlineAddchart, MdPrint, MdSearch, } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import styles from "../sellitem/sellitems.module.css";
import { Delete, Edit, Preview } from '@mui/icons-material';
import fl from '../../assets/fl copy.png';
import DatePickerIcon from '@mui/icons-material/DateRange';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete } from '@mui/material';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import AddProductDialog from './AddProductDialog';
import { showDeleteConfirmation } from '../../DIALOG/sweetAlert'; // Import the SweetAlert utility
import PaymentTotalIndicator from '../sellitem/PaymentTotalIndicator';  // Adjust the import path as needed
import MultiPageInvoice from './MultiPageInvoice';
import AddIcon from '@mui/icons-material/Add';

const getCurrentDate = () => {
  return new Date().toISOString().split('T')[0];
};

const apiurl = process.env.REACT_APP_API_URL;

const initialState = {
  formValues: {
    salesInvoiceId: '',
    storeId: '',  // Add this line
    storeName: '',
    customerName: '',
    contactNo: '',
    customerAddress: '',
    city: '',
    state: " ",
    pincode: '',
    amount: '',
    // modeOfPayment: '',
    cashAmount: '',
    upiAmount: '',
    date: getCurrentDate(),
    extraAmountAdd: '',
    discount: '',
    grandTotal: '',
  },
  errors: {},
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUES':
      return { ...state, formValues: { ...state.formValues, ...action.payload } };
    case 'SET_ERRORS':
      return { ...state, errors: { ...state.errors, ...action.payload } };
    case 'RESET_FORM':
      return {
        ...state,
        formValues: { ...initialState.formValues, storeName: '', productName: '' },
        errors: {}
      };
    default:
      return state;
  }
};

const Sales = () => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [salesList, setSalesList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [salseInvoiceNo, setsalseInvoiceNo] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSaleIndex, setSelectedSaleIndex] = useState(null);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [InvoiceData, setInvoiceData] = useState(null);
  const [othersAmount, setOthersAmount] = useState('');
  const [states, setStates] = useState([]);
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [salesInvoiceId, setsalesInvoiceId] = useState(null);

  //=================================================user api==============================================================
  const [userRole, setUserRole] = useState('');
  const [userStore, setUserStore] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${apiurl}user/loggeduser`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('User data:', data);
        setUserRole(data.user.roleName.toLowerCase());
        console.log('User role set to:', data.user.roleName.toLowerCase());
        if (data.user.roleName.toLowerCase() === 'store') {
          setUserStore({
            id: data.user.storeId,
            storeName: data.user.storeName
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);


  //++++++++++++++++++++++++++loDING----------------------------------------------------------------------------

  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <CircularProgress />
    </div>
  );
  const [products, setProducts] = useState([{
    id: '',
    productName: '',
    // unit: '',
    category: '',
    sellRate: '',
    pieces: '',
    amount: ''
  }]);

  // Update addProductLine function
  const isRowFilled = (product) => {
    const isFilled = product.amount;
    return isFilled;
  };


  const addProductLine = () => {
    if (products.length === 0 || isRowFilled(products[products.length - 1])) {
      const newProduct = {
        id: '',
        productName: '',
        category: '',
        rate: '',
        pieces: '',
        amount: ''
      };
      setProducts([...products, newProduct]);
    } else {
      alert("Please fill out the current row before adding a new one.");
    }
  };
  //===================================== formatIndianCurrency ======================================================================
  const formatIndianCurrency = (x) => {
    if (x === undefined || x === null) return '0.00';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };


  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };

  //====================================================================================

  const handleProductChange = (index, field, value) => {

    if (['rate', 'pieces', 'unit',].includes(field)) {
      if (!/^\d*\.?\d*$/.test(value)) {
        // If the input is not a valid number, don't update the state
        return;
      }
    }

    const newProducts = [...products];

    if (field === 'productName') {
      const selectedProduct = productsData.find(p => p.id === value);
      if (selectedProduct) {
        newProducts[index] = {
          ...newProducts[index],
          id: selectedProduct.id, // Add this line
          productName: selectedProduct.productName,
          category: selectedProduct.category,

        };
      }
    } else {
      newProducts[index][field] = value;
    }

    // Calculate amount if rate and pieces are present
    if (newProducts[index].rate && newProducts[index].pieces) {
      const rate = parseFloat(newProducts[index].rate) || 0;
      const pieces = parseFloat(newProducts[index].pieces) || 0;
      const discount = parseFloat(newProducts[index].discount) || 0;
      const amount = rate * pieces - discount;
      newProducts[index].amount = amount.toFixed(2);
    }

    setProducts(newProducts);
  };

  const tableCellStyle = {
    height: '25px',
    padding: '4px',
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333'
  };

  const calculateTotals = () => {
    const subtotal = products.reduce((sum, product) => {
      const amount = parseFloat(product.amount || 0);
      return sum + amount;
    }, 0);

    const extraAmountAddValue = parseFloat(othersAmount) || 0;
    const discountValue = parseFloat(totalDiscount) || 0;
    const subtotalWithExtras = subtotal + extraAmountAddValue;
    const grandTotal = Math.max(subtotalWithExtras - discountValue, 0).toFixed(2);

    return {
      subtotal: subtotal.toFixed(2),
      extraAmountAdd: extraAmountAddValue.toFixed(2),
      grandTotal: grandTotal
    };
  };
  const [query, setQuery] = useState('');

  const fetchSalesInvoice = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      let url = new URL(`${apiurl}salesInvoice`);
      // Add parameters to the URL
      url.searchParams.append('page', currentPage);
      url.searchParams.append('limit', rowsPerPage);

      if (fromDate) {
        url.searchParams.append('fromDate', fromDate);
      }
      if (toDate) {
        url.searchParams.append('toDate', toDate);
      }
      if (query) {
        url.searchParams.append('query', query);
      }

      // console.log('Fetching URL:', url.toString()); // Log the full URL

      const response = await fetch(url.toString(), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch sales');
      }

      const data = await response.json();

      // Logging the raw response data to debug the JSON structure
      // console.log('Fetched data:', data);


      if (data.status === 'success' && Array.isArray(data.invoices)) {
        const sortedSales = data.invoices.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setSalesList(sortedSales);
        setTotalPages(data.numberOfPages);

        // Update total pages
        const totalPages = data.numberOfPages || Math.ceil(data.totalInvoices / rowsPerPage);
        setTotalPages(totalPages);
      } else {
        console.error('Unexpected data structure:', data);
        setSalesList([]);
      }

      if (data.totalInvoices) {
        localStorage.setItem('salesInvoice', data.totalInvoices.toString());
      }
    } catch (error) {
      console.error('Error fetching sales:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    fetchSalesInvoice();
    // setQuery(''); // Reset the search input after searching
  };


  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    fetchSalesInvoice();

  };
  useEffect(() => {

    fetchSalesInvoice();
  }, [currentPage, rowsPerPage]);


  //==================================================reset==============================
  // Add this new function to reset the form
  const resetForm = () => {
    dispatch({ type: 'RESET_FORM' });
    dispatch({ type: 'SET_FORM_VALUES', payload: { date: currentDate } });
    setProducts([{
      id: '',
      productName: '',
      category: '',
      sellRate: '',
      discount: '',
      pieces: '',
      amount: ''
    }]);
  };

  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    // Set the default date to the current date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0];
    setCurrentDate(today);
  }, []);


  const handleInputChange = (e) => {

    const { name, value } = e.target;
    if (name === 'storeId') {
      if (userRole === 'store') {
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: {
            storeId: userStore.id,
            storeName: userStore.storeName
          }
        });
      } else {
        const selectedStore = stores.find(store => store.id === value);
        dispatch({
          type: 'SET_FORM_VALUES',
          payload: {
            storeId: value,
            storeName: selectedStore ? selectedStore.storeName : ''
          }
        });
      }
    } else if (name === 'date') {
      // If the date is changed, update it in the form values
      dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: value } });
    } else {
      dispatch({ type: 'SET_FORM_VALUES', payload: { [name]: value } });
    }
    dispatch({ type: 'SET_ERRORS', payload: { [name]: '' } });
  };

  const validateForm = () => {
    const { storeId, customerName, contactNo, cashAmount, upiAmount } = state.formValues;
    const newErrors = {};

    if (!customerName) newErrors.customerName = "Customer Name is required";
    if (!contactNo) newErrors.contactNo = "Contact is required";
    if (!cashAmount) newErrors.cashAmount = "Cash Amount is required";
    if (!upiAmount) newErrors.upiAmount = "UPI Amount is required";

    // Only validate storeId for admin and staff roles
    if (userRole !== 'store' && !storeId) {
      newErrors.storeId = 'Store Name is required';
    }

    // Check if the sum of cashAmount and upiAmount equals grandTotal
    const totalPayment = parseFloat(cashAmount || 0) + parseFloat(upiAmount || 0);
    const grandTotal = parseFloat(calculateTotals().grandTotal);

    if (totalPayment !== grandTotal) {
      newErrors.paymentTotal = `Total payment (${totalPayment}) must equal Grand Total (${grandTotal})`;
    }

    dispatch({ type: 'SET_ERRORS', payload: newErrors });
    return Object.keys(newErrors).length === 0;
  };

  const handleAddOrUpdateDetails = async (e) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    e.preventDefault();

    if (!validateForm()) {
      setErrorSnackbarOpen(true);
      setIsLoading(false);
      return;
    }

    const newSales = {

      storeId: userRole === 'store' ? userStore.id : parseInt(state.formValues.storeId),
      customerName: state.formValues.customerName,
      contactNo: state.formValues.contactNo,
      customerAddress: state.formValues.customerAddress,
      city: state.formValues.city,
      state: state.formValues.state,
      pinCode: state.formValues.pinCode,
      date: state.formValues.date,
      cashAmount: parseFloat(state.formValues.cashAmount),
      upiAmount: parseFloat(state.formValues.upiAmount),
      extraAmountAdd: parseFloat(othersAmount) || 0,
      grandTotal: parseFloat(calculateTotals().grandTotal),
      discount: parseFloat(totalDiscount) || 0,

      products: products.map(product => {
        // console.log("Mapping product:", { id: (product.id) });
        return {
          id: parseInt(product.id),
          pieces: product.pieces,
          rate: parseFloat(product.rate),
          amount: parseFloat(product.amount)
        }
      })
    };
    // Add salesInvoiceId only when updating
    if (editIndex !== null) {
      newSales.salesInvoiceId = salseInvoiceNo;
    }
    try {
      let response;
      if (editIndex !== null) {
        response = await fetch(`${apiurl}salesInvoice/${salseInvoiceNo}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },

          body: JSON.stringify(newSales),
        });
      } else {
        response = await fetch(`${apiurl}salesInvoice`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },

          body: JSON.stringify(newSales),
        });
      }
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to add/update sales: ${errorData.message || response.statusText}`);

      }

      const updatedSales = await response.json();
      // console.log('purchase added/updated successfully', newPurchaseData);

      setSalesList(prevList => {
        if (editIndex !== null) {
          // Update existing purchase
          showAlert('Sales updated successfully', 'success');
          return prevList.map(salesInvoice =>
            salesInvoice.salseInvoiceNo === updatedSales.salseInvoiceNo ? updatedSales : salesInvoice
          );
        } else {
          // Add new salesInvoice
          showAlert('Sales Data added successfully', 'success');
          return [updatedSales, ...prevList];
        }
      });

      // Reset form and close dialog
      resetForm();
      setShowForm(false);
      setEditIndex(null);
      setProducts([]);

      fetchSalesInvoice();

    } catch (error) {
      showAlert(`Failed to ${editIndex !== null ? 'update' : 'add'} salesInvoice: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };

  const handleConfirmDeleteOpen = (salesInvoiceId) => {
    setDeleteIndex(salesInvoiceId);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setDeleteIndex(null);
  };

  const handleDeleteDetails = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      if (!deleteIndex) {
        throw new Error('Invalid sales selected for deletion');
      }

      const response = await fetch(`${apiurl}salesInvoice/${deleteIndex}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },

      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete sales');
      }

      console.log('Sales deleted successfully');

      // Update the sale list
      setSalesList((prevSalesList) => {
        const updatedList = prevSalesList.filter((salesInvoice) => salesInvoice._id !== deleteIndex);

        // If the current page is now empty and it's not the first page, go to the previous page
        if (updatedList.length === 0 && currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }

        return updatedList;
      });

      // Fetch the updated product list
      fetchSalesInvoice();

      showAlert('Sales deleted successfully', 'success');

    } catch (error) {
      console.error('Error deleting Sales:', error);
      showAlert(`Failed to delete Sales: ${error.message}`, 'error');
    } finally {
      setConfirmDeleteOpen(false);
      setDeleteIndex(null);
      setIsLoading(false);
    }
  };


  const handleEditSales = (salseInvoiceNo, index) => {
    setsalseInvoiceNo(salseInvoiceNo);

    const actualIndex = (currentPage - 1) * itemsPerPage + index;
    // Use the _id directly to find the product instead of relying on the newly set state
    const salesToEdit = salesList.find(sales => sales.salseInvoiceNo === salseInvoiceNo);

    setsalesInvoiceId(salesToEdit.salesInvoiceId); // Ensure this is set correctly

    if (!salesToEdit) {
      console.error('Sales not found:', salseInvoiceNo, index, actualIndex);
      showAlert('Error: Sales not found', 'error');
      return;
    }

    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        storeId: salesToEdit?.storeId || '',
        customerName: salesToEdit?.customerName || '',
        contactNo: salesToEdit?.contactNo || '',
        customerAddress: salesToEdit?.customerAddress || '',
        state: salesToEdit?.state || '',
        city: salesToEdit?.city || '',
        pinCode: salesToEdit?.pinCode || '',
        date: currentDate,
        modeOfPayment: salesToEdit?.modeOfPayment || '',
        cashAmount: salesToEdit?.cashAmount ?? '',
        upiAmount: salesToEdit?.upiAmount ?? '',

      }
    });
    console.log("Dispatched form values: ", {
      state: salesToEdit?.state,
      pinCode: salesToEdit?.pinCode,
      cashAmount: salesToEdit?.cashAmount,
      upiAmount: salesToEdit?.upiAmount
    });

    // Set the products array with the existing products
    if (salesToEdit?.productDetails && salesToEdit?.productDetails.length > 0) {
      // console.log("salesData", salesToEdit.products)
      setProducts(salesToEdit?.productDetails?.map(product => ({
        _id: product?._id, // Add this line
        id: product?.productId, // Add this line
        category: product?.categoryName,
        rate: product?.rate || '',
        pieces: product?.pieces || '',
        discount: product?.discount || '',
        amount: product?.amount || '',
      })));
    } else {
      setProducts([]); // Reset products if there are none
    }


    setShowForm(true);
    setEditIndex(salesList.findIndex(sales => sales.salseInvoiceNo === salseInvoiceNo));
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedSaleIndex(null);
  };

  const handlePreviewOpen = async (salesId) => {

    try {
      const response = await fetch(`${apiurl}salesInvoice/${salesId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch sales details');
      const data = await response.json();
      setInvoiceData(data?.invoice);
      console.log('Invoice data:', data.invoice);
      setPreviewOpen(true);
    } catch (error) {
      console.error('Error fetching purchase details:', error);
    }
  };


  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setInvoiceData(null);
  };

  const handlePrint = () => {
    window.print();
  };


  //store define api======================================================================================
  const fetchstores = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiurl}store`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch stores');
      }
      const data = await response.json();
      console.log('Fetched store data:', data);

      if (data.status === 'success' && Array.isArray(data.stores)) {
        const storeList = data.stores.map(store => ({
          id: store.storeId, // या store.id, जो भी आपके API में उपयोग हो रहा है
          storeName: store.storeName,
          city: store.city
        }));
        setStores(storeList);
      } else {
        console.error('Unexpected data format:', data);
        setStores([]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setStores([]);
    }
  };
  useEffect(() => {
    fetchstores();
  }, []);

  useEffect(() => {
    console.log('Updated stores:', stores);
  }, [stores]);



  //states define api
  const fetchStates = async () => {
    const token = localStorage.getItem('token');

    try {
      // console.log('Fetching states...');
      const response = await fetch(`${apiurl}state`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      // console.log('Response status:', response.status);
      if (!response.ok) {
        throw new Error('Failed to fetch state');
      }
      const data = await response.json();
      // console.log('Fetched state data:', data);
      if (Array.isArray(data) && data.length > 0) {
        console.log('First state item:', data[0]);
      } else {
        console.log('Data is not an array or is empty');
      }
      setStates(data);
    } catch (error) {
      console.error('Error fetching state:', error);
    }
  };

  useEffect(() => {
    console.log('Calling fetchStates');
    fetchStates();
  }, []);

  //========================================Add Extra Prodcuts====================================================================

  const [productsData, setProductsData] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [addProductDialogOpen, setAddProductDialogOpen] = useState(false);

  const fetchProducts = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiurl}product/list`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();

      if (data.status === 'success' && Array.isArray(data.products)) {
        const productList = data.products.map(product => ({
          id: product.productId,
          productName: product.productName,
          category: product.categoryName,
          unit: product.unitName,
          sellRate: product.sellRate,
        }));
        setProductsData(productList);
      } else {
        console.error('Unexpected data format:', data);
        setProductsData([]);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setProductsData([]);
    }
  }, []);

  useEffect(() => {
    console.log('Calling fetchProducts');
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    console.log('Updated productsData:', productsData);
  }, [productsData]);

  const handleAddNewProduct = async (newProduct) => {
    setAddProductDialogOpen(false);
    await fetchProducts(); // Re-fetch the entire product list after adding a new product
  };

  const removeProductLine = async (product) => {
    const token = localStorage.getItem('token');

    // Use the _id field from the product
    const productDetailId = product._id;

    // Immediately remove the product from the UI, even if the ID is missing
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.filter((p) => p !== product);
      console.log('Updated products list:', updatedProducts);
      return updatedProducts;
    });

    // If the product has an _id, proceed with the API call to delete it from the server
    if (productDetailId) {
      try {
        console.log('Attempting to delete product with _id:', productDetailId);

        const response = await fetch(`${apiurl}salesInvoice/product/${productDetailId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Server response:', errorData);
          console.error('Response status:', response.status);
          console.error('Response statusText:', response.statusText);
          throw new Error(errorData.message || `Failed to delete product: ${response.statusText}`);
        }

        console.log('Product deleted from the server successfully');
      } catch (error) {
        console.error('Error deleting product from the server:', error);
        throw error;
      }
    } else {
      console.warn('Product does not have an _id, skipping server deletion');
    }
  };




  //=====================================================

  return (
    <div className={styles.container}>
      <div className={styles.maincontainer}>
        {isLoading && <LoadingOverlay />}
        <Grid container spacing={2} >
          <Grid item>
            <TextField
              name="fromDate"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="toDate"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              sx={{
                ...commonTextFieldStyles,
                width: '150px',
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              name="query"
              label="Search by Customer Name or Contact"
              value={query}
              onChange={handleQueryChange}
              InputProps={{
                className: styles.invoiceinput,
              }}
              InputLabelProps={{
                className: styles.invoicelabel,
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdSearch />}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>

          <Grid item >
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
              startIcon={<MdOutlineAddchart />}
              onClick={() => setShowForm(true)}
              style={{ justifyContent: 'flex-end' }}
            >
              Add Sale
            </Button>
          </Grid>
        </Grid>


        <Dialog
          open={showForm}
          onClose={() => { }}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            sx: {
              maxWidth: 'auto',
              maxHeight: 'auto',
              margin: 'auto',
              mt: 0, // Set the margin-top to 0
            },
          }}
          sx={{
            display: 'flex',
            alignItems: 'flex-start', // Align dialog to the top
            justifyContent: 'center',
          }}
        >
          <DialogContent>
            <div className={styles.invoicemaincontainer}>
              <div className={styles.invoicecontentContainer}>
                <h2 className={styles.invoiceheader}> Sales Invoice</h2>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ mt: -5 }}
                >
                  <Grid container spacing={2}>

                    <Grid item xs={2}>
                      <FormControl
                        fullWidth
                        error={!!state.errors.storeId}
                        disabled={userRole === 'store'}
                      >
                        <InputLabel sx={{ top: '-6px', color: '#555', '&.Mui-focused': { color: '#009999' } }}>Store<span style={{ color: 'red' }}>*</span></InputLabel>
                        <Select
                          name="storeId"
                          value={userRole === 'store' ? userStore?.id : state.formValues.storeId}
                          onChange={handleInputChange}
                          label="Store"
                          className={styles.invoiceinput}
                          required={userRole !== 'store'}
                        >
                          {userRole === 'store' ? (
                            <MenuItem value={userStore?.id}>{userStore?.storeName}</MenuItem>
                          ) : (
                            [
                              <MenuItem key="none" value=""><em>None</em></MenuItem>,
                              ...stores.map((store) => (
                                <MenuItem key={store.id} value={store.id}>
                                  {store.storeName}
                                </MenuItem>
                              ))
                            ]
                          )}
                        </Select>
                        {state.errors.storeId && (
                          <FormHelperText>{state.errors.storeId}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        name="customerName"
                        label="Customer Name"
                        type="text"
                        required={{ color: "red" }}
                        value={state.formValues.customerName}
                        fullWidth
                        error={!!state.errors.customerName}
                        helperText={state.errors.customerName}
                        onChange={handleInputChange}
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                            // Hide the default asterisk and apply custom styles
                            '& .MuiFormLabel-asterisk': {
                              color: 'red', // Customize the color of the asterisk
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Text color
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        name="contactNo"
                        label="Contact No"
                        value={state.formValues.contactNo}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Check if the value is either empty or only numeric
                          if (value === '' || /^\d*$/.test(value)) {
                            handleInputChange(e); // Assuming handleInputChange updates the state
                          }
                        }}
                        required
                        fullWidth
                        inputProps={{
                          pattern: "[0-9]*",
                          inputMode: "numeric",
                          maxLength: 10,
                        }}
                        error={!!state.errors.contactNo}
                        helperText={state.errors.contactNo}
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                            // Hide the default asterisk and apply custom styles
                            '& .MuiFormLabel-asterisk': {
                              color: 'red', // Customize the color of the asterisk
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Text color
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        name="customerAddress"
                        label="Address"
                        value={state.formValues.customerAddress}
                        onChange={handleInputChange}
                        fullWidth
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Adjust text color if needed
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <FormControl fullWidth error={!!state.errors.state}>
                        <InputLabel>State</InputLabel>
                        <Select
                          name="state"
                          value={state.formValues.state}
                          onChange={(e) => handleInputChange({
                            target: {
                              name: 'state',
                              value: e.target.value
                            }
                          })} label="State"
                          className={styles.invoiceinput}                  >
                          <MenuItem value=""><em>None</em></MenuItem>
                          {Array.isArray(states) ? (
                            states.map((stateItem) => (
                              <MenuItem key={stateItem.id || stateItem._id} value={stateItem.state || stateItem.name}>
                                {stateItem.state || stateItem.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value=""><em>No states available</em></MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        name="city"
                        label="City"
                        value={state.formValues.city}
                        onChange={handleInputChange}
                        fullWidth
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Adjust text color if needed
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        name="pinCode"
                        label="Pincode"
                        value={state.formValues.pinCode}
                        onChange={(e) => handleInputChange({
                          target: {
                            name: 'pinCode',
                            value: e.target.value
                          }
                        })}
                        fullWidth
                        inputProps={{
                          pattern: "[0-9]{6}",
                          inputMode: "numeric",
                          maxLength: 6,
                        }}
                        error={!!state.errors.pinCode}
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Adjust text color if needed
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        name="date"
                        type="date"
                        value={state.formValues.date}

                        onChange={handleInputChange}
                        fullWidth
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Adjust text color if needed
                          },
                        }}
                        InputAdornment={{ endAdornment: <DatePickerIcon className={styles.datePickerIcon} /> }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        name="cashAmount"
                        label="Enter Cash Amount"
                        value={state.formValues.cashAmount || ''}
                        required={{ color: "red" }}
                        error={!!state.errors.cashAmount}
                        helper={!!state.errors.cashAmount}
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, ''); // Remove commas
                          if (value === '' || /^[0-9]{0,11}$/.test(value)) { // Allow up to 11 digits
                            handleInputChange({
                              target: {
                                name: 'cashAmount',
                                value: value
                              }
                            });
                          }
                        }}
                        fullWidth
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                            // Hide the default asterisk and apply custom styles
                            '& .MuiFormLabel-asterisk': {
                              color: 'red', // Customize the color of the asterisk
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Text color
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        name="upiAmount"
                        label="Enter UPI Amount"
                        value={formatIndianCurrency(state.formValues.upiAmount)}
                        required={{ color: "red" }}
                        error={!!state.errors.upiAmount}
                        helper={!!state.errors.upiAmount}
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, ''); // Remove commas
                          if (value === '' || /^[0-9]{0,11}$/.test(value)) { // Allow up to 11 digits
                            handleInputChange({
                              target: {
                                name: 'upiAmount',
                                value: value
                              }
                            });
                          }
                        }}
                        fullWidth
                        InputProps={{ className: styles.invoiceinput }}
                        sx={{
                          '& .MuiInputLabel-root': {
                            top: '-6px',
                            color: '#555',
                            '&.Mui-focused': {
                              color: '#009999',
                            },
                            // Hide the default asterisk and apply custom styles
                            '& .MuiFormLabel-asterisk': {
                              color: 'red', // Customize the color of the asterisk
                            },
                          },
                          '& .MuiInputBase-root': {
                            color: '#000', // Text color
                          },
                        }}
                      />
                    </Grid>

                  </Grid>

                </Box>
                <table className={styles.invoicetabledata}>
                  <thead>
                    <tr>
                      <th className={styles.invoiceTh}>Sr_No</th>
                      <th className={styles.invoiceTh}>Select Product</th>
                      <th className={styles.invoiceTh}>Category</th>
                      <th className={styles.invoiceTh}>Rate</th>
                      <th className={styles.invoiceTh}>Pieces</th>
                      <th className={styles.invoiceTh}>Amount</th>
                      <th className={styles.invoiceTh}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product, index) => {
                      // Create a list of already selected product IDs
                      const selectedProductIds = products.map(p => p.id);
                      return (
                        <tr key={index}>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ddd' }}>
                            {index + 1}
                          </td>
                          <td className={styles.invoiceTd}>
                            <Autocomplete
                              key={productsData.length} // Force re-render when productsData changes
                              options={[
                                { id: 'add-new', productName: '+ Add a New Product', category: '', sellRate: '' },
                                ...productsData.filter(p => !selectedProductIds.includes(p.id))
                              ]}
                              getOptionLabel={(option) => option.productName || ''}
                              onChange={(event, newValue) => {
                                if (newValue && newValue.id === 'add-new') {
                                  setAddProductDialogOpen(true);
                                } else {
                                  // Update product fields when a product is selected
                                  handleProductChange(index, 'id', newValue ? newValue.id : '');
                                  handleProductChange(index, 'productName', newValue ? newValue.productName : '');
                                  handleProductChange(index, 'category', newValue ? newValue.category : '');
                                  handleProductChange(index, 'rate', newValue ? newValue.sellRate : '');
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Type to search..."
                                  sx={{
                                    width: '220px',
                                    '& .MuiOutlinedInput-root': {
                                      height: "30px",
                                      borderRadius: '0',
                                      '& fieldset': {
                                        borderColor: '#888',
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#333',
                                      },
                                    },
                                    '& .MuiInputBase-input': {
                                      width: "100%",
                                    }
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  style={{
                                    backgroundColor: option.id === 'add-new' ? '#009999' : 'white',
                                    color: option.id === 'add-new' ? '#ffffff' : '#000000',
                                    fontFamily: option.id === 'add-new' ? 'Arial, sans-serif' : 'inherit',
                                    fontSize: option.id === 'add-new' ? '14px' : 'inherit',
                                    fontWeight: option.id === 'add-new' ? 'semibold' : 'normal',
                                  }}
                                >
                                  {option.productName}
                                </li>
                              )}
                              filterOptions={(options, { inputValue }) =>
                                options.filter(option =>
                                  option.productName && option.productName.toLowerCase().startsWith(inputValue.toLowerCase())
                                )
                              }
                            />

                          </td>

                          <td className={styles.invoiceTd}>
                            <input
                              type="text"
                              value={product.category || ''}
                              disabled
                              className={styles.invoiceinputs}
                            />
                          </td>
                          <td className={styles.invoiceTd}>
                            <input
                              type="text"
                              value={formatIndianCurrency(product.rate)}
                              onChange={(e) => {
                                const value = e.target.value.replace(/,/g, ''); // Remove commas if any

                                // Allow only numbers and ensure it doesn't exceed 11 digits
                                if (value === '' || /^\d{1,11}(\.\d{0,2})?$/.test(value)) {
                                  handleProductChange(index, 'rate', value); // Call the change handler with valid input
                                }
                              }}
                              className={styles.invoiceinputs}
                              style={{ textAlign: 'right' }}
                            />
                          </td>
                          <td className={styles.invoiceTd}>
                            <input
                              value={product.pieces}
                              onChange={(e) => handleProductChange(index, 'pieces', e.target.value)}
                              className={styles.invoiceinputs}
                              style={{ textAlign: 'right' }}
                            />
                          </td>
                          <td className={styles.invoiceTd}>
                            <input
                              type="text"
                              value={formatIndianCurrency(product.amount)}
                              readOnly
                              className={styles.invoiceinputs}
                              style={{ textAlign: 'right' }}
                            />
                          </td>
                          <td className={styles.invoiceTd}>
                            <IconButton
                              onClick={(event) => showDeleteConfirmation(() => removeProductLine(product))}
                              className={styles.deleteButton}
                            >
                              <ClearIcon className={styles.invoiceicons} />
                            </IconButton>

                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className={styles.others}>
                  <tbody>
                    <tr>
                      <td><label>Others Product Name:</label></td>
                      <td>
                        <input
                          type="text"
                          className={styles.invoiceinputs}
                        />
                      </td>
                      <td><label>Others Amount:</label></td>
                      <td>
                        <input
                          value={formatIndianCurrency(othersAmount)}
                          onChange={(e) => {
                            const value = e.target.value.replace(/,/g, ''); // Remove commas

                            // Allow only numbers and ensure it doesn't exceed 11 digits, allowing optional decimal points
                            if (value === '' || /^[0-9]{0,11}(\.\d{0,2})?$/.test(value)) {
                              setOthersAmount(value); // Update state if valid input
                            }
                          }}
                          className={styles.invoiceinputs}
                          style={{ textAlign: 'right' }}
                        />

                      </td>
                    </tr>
                  </tbody>
                </div>
                <Button sx={{
                  backgroundColor: '#009999',
                  color: '#fff',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: '#01dcb8',
                  },
                }} onClick={addProductLine}>+ Add Line</Button>

                <div className={styles.summary}>
                  <div>Sub Total: <span>₹{formatIndianCurrency(calculateTotals().subtotal)}</span></div>
                  <div>Extra Amount: <span>₹{formatIndianCurrency(calculateTotals().extraAmountAdd)}</span></div>
                  <div>
                    Total Discount:
                    <input
                      value={formatIndianCurrency(totalDiscount)}
                      onChange={(e) => {
                        const value = e.target.value.replace(/,/g, ''); // Remove commas for parsing

                        // Allow only numbers and ensure it doesn't exceed 11 digits
                        if (value === '' || /^[0-9]{0,11}$/.test(value)) {
                          setTotalDiscount(parseFloat(value) || 0); // Update state if valid
                        }
                      }}
                      className={styles.invoiceinputs}
                      style={{ width: '80px', marginLeft: '10px', textAlign: 'right' }}
                    />

                  </div>
                  <div>Grand Total: <span>₹{formatIndianCurrency(calculateTotals().grandTotal)}</span></div>

                  {/* Add PaymentTotalIndicator here */}
                  <PaymentTotalIndicator
                    cashAmount={state.formValues.cashAmount}
                    upiAmount={state.formValues.upiAmount}
                    grandTotal={calculateTotals().grandTotal}
                  />

                  <AddProductDialog
                    open={addProductDialogOpen}
                    onClose={() => setAddProductDialogOpen(false)}
                    onAddProduct={handleAddNewProduct}
                  />
                </div>


                <DialogActions sx={{ mt: 2, justifyContent: "flex-start" }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#009999',
                      color: '#fff',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#01dcb8',
                      },
                    }}
                    onClick={handleAddOrUpdateDetails}
                    startIcon={<AddIcon />}
                  >
                    {editIndex !== null ? 'Update Sales' : 'Add Sales'}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#e32e2e',
                      color: "fff",
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#ff0000',
                      },
                    }}

                    onClick={() => {
                      resetForm();
                      setShowForm(false);
                      setEditIndex(null);

                    }}
                    startIcon={<CloseIcon />}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight: 5, marginBottom: 5, marginTop: 3 }}>
          <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
          <Select
            labelId="rows-per-page-label"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            label="Rows per page"
          >
            {[10, 25, 50, 100].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className={styles.tablecontainer}>
          <TableContainer component={Paper}>
            <Table className={styles.storetable}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                  {userRole !== 'store' && (
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Store Name</TableCell>
                  )}
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Customer Name</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Customer Contact</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Customer Address</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>City</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Date</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {salesList?.map((salesInvoice, index) => (
                  <TableRow key={salesInvoice._id} style={{ backgroundColor: !salesInvoice.isActive ? '#ffcccc' : index % 2 === 0 ? 'white' : '#f0f0f0' }}>
                    <TableCell style={tableCellStyle}>
                      {((currentPage - 1) * rowsPerPage + index + 1).toString().padStart(4, '0')}
                    </TableCell>

                    {userRole !== 'store' && (
                      <TableCell style={tableCellStyle}>
                        {Array.isArray(salesInvoice.storeDetails) ? (
                          salesInvoice.storeDetails.map((store, idx) => (
                            <div key={idx}>{store.storeName}</div>
                          ))
                        ) : (
                          <div>{salesInvoice.storeDetails?.storeName}</div>  // Adjust this line based on how you want to handle non-array storeDetails
                        )}
                      </TableCell>
                    )}


                    <TableCell style={tableCellStyle}>{salesInvoice.customerName}</TableCell>
                    <TableCell style={tableCellStyle}>{salesInvoice.contactNo}</TableCell>
                    <TableCell style={tableCellStyle}>{salesInvoice.customerAddress}</TableCell>
                    <TableCell style={tableCellStyle}>{salesInvoice.city}</TableCell>
                    <TableCell style={tableCellStyle}>{salesInvoice.createdAt}</TableCell>
                    <TableCell style={tableCellStyle}>
                      <IconButton onClick={() => handlePreviewOpen(salesInvoice._id)} >
                        <Preview className={styles.prew} />
                      </IconButton>
                      <IconButton onClick={() => handleEditSales(salesList[index].salseInvoiceNo, index + (currentPage - 1) * itemsPerPage)} >
                        <Edit className={styles.editIcon} />
                      </IconButton>
                      <IconButton onClick={() => handleConfirmDeleteOpen(salesInvoice._id)} >
                        <Delete className={styles.editDelete} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>

            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#009999',
                  border: '1px solid #ddd',
                  margin: '0 4px',
                  borderRadius: '4px',
                },
                '& .Mui-selected': {
                  backgroundColor: '#009999',
                  color: 'white',
                },
              }}
            />
          </Box>
        </div>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => {
            handleEditSales(salesList[selectedSaleIndex].salseInvoiceNo, selectedSaleIndex);
            handleMenuClose();
          }}>
            <MdEdit style={{ color: 'green', marginRight: '8px' }} /> Update
          </MenuItem>

          <MenuItem onClick={() => {
            handleConfirmDeleteOpen(selectedSaleIndex);
            handleMenuClose();
          }}>
            <MdDeleteForever
              style={{ color: 'red', marginRight: '8px' }} /> Delete
          </MenuItem>


          <MenuItem onClick={() => {
            handlePreviewOpen(selectedSaleIndex);
          }}>
            <MdPreview style={{ color: 'chocolate', marginRight: '8px' }} /> Preview
          </MenuItem>
        </Menu>

        <MultiPageInvoice
          InvoiceData={InvoiceData}
          previewOpen={previewOpen}
          handlePreviewClose={handlePreviewClose}
          handlePrint={handlePrint}
        />

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            Please fill in all fields correctly.
          </Alert>
        </Snackbar>

        <Dialog
          open={confirmDeleteOpen}
          onClose={handleConfirmDeleteClose}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this sale list?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleConfirmDeleteClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDeleteDetails} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* ====================================================alert======================================================= */}

        {alertInfo.open && (
          <Alert
            severity={alertInfo.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertInfo({ ...alertInfo, open: false })}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={alertInfo.severity === 'success' ? <CheckCircleOutlineIcon /> : <WarningIcon />}
            sx={{
              position: 'fixed',
              top: 16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
            }}
          >
            {alertInfo.message}
          </Alert>
        )}
      </div>
    </div>
  );
};
export default Sales;