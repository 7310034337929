import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Dialog,  DialogContent, Box, Grid,  MenuItem,
  CircularProgress, FormControl, InputLabel, Select,Autocomplete
} from '@mui/material';
import { MdSearch } from 'react-icons/md';
import Pagination from '@mui/lab/Pagination';
import styles from './transfer.module.css';
const apiurl = process.env.REACT_APP_API_URL;
const TransferHistory = ({product}) => {

  const [inventoryList, setInventoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [search, setSearch] = useState('');
  const [storeName, setStoreName] = useState('');
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [stores, setStores] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [storeIds, setStoreIds] = useState(''); // Changed from storeName to storeId
  const [storeSearch, setStoreSearch] = useState('');
  //============================================================


  useEffect(() => {
    const storedRole = localStorage.getItem("roleName");
    setUserRole(storedRole || "");
  }, []);

  const commonTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#888',
      },
      '&:hover fieldset': {
        borderColor: '#555',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009999',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#555',
      '&.Mui-focused': {
        color: '#009999',
      },
    },
    '& .MuiInputBase-input': {
      color: '#333',
    },
  };

  const handleImageClick = (imageSrc) => {
    setEnlargedImage(imageSrc);
  };
  const handleCloseEnlargedImage = () => {
    setEnlargedImage(null);
  };
  const handleStoreChange = (event, newValue) => {
    setStoreIds(newValue ? newValue.id : '');
  };

  const handleStoreInputChange = (event, newInputValue) => {
    setStoreSearch(newInputValue);
  };


  const tableCellStyle = {
    height: '25px',
    padding: '4px',
    textAlign: 'center',
    fontSize: '13px',
    border: '1px solid #333',
    };

  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <CircularProgress />
    </div>
  );

  //================================alert =====================================================================================
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
    setTimeout(() => setAlertInfo({ open: false, message: '', severity: 'success' }), 5000); // Close after 5 seconds
  };


  const [totalPages, setTotalPages] = useState(1);

  const fetchInventory = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      let url = new URL(`${apiurl}inventory`);

      url.searchParams.append('page', currentPage);
      url.searchParams.append('limit', rowsPerPage);
      if (fromDate) url.searchParams.append('fromDate', fromDate);
      if (toDate) url.searchParams.append('toDate', toDate);
      if (search) url.searchParams.append('search', search);
      if (storeIds) url.searchParams.append('storeIds', storeIds);


      const response = await fetch(url.toString(), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch inventory');
      }
        setInventoryList(data.inventory);
        setTotalPages(data.totalPages || 1);
    } catch (error) {
      showAlert(error.message, 'error');
      console.error('Fetch Error:', error);
    } finally {
      setLoading(false);
    }
  }, 
);


  const handleQueryChange = (e) => {
    setSearch(e.target.value);
  };
  

  const handleSearch = () => {
    fetchInventory();
    // setSearch('');
  };


const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to the first page whenever rows per page is changed
};

const handlePageChange = (event, newPage) => {
    if (!isNaN(newPage)) {
        setCurrentPage(newPage);
    }
};

  useEffect(() => {
    fetchInventory();
  }, [currentPage, rowsPerPage]); // Remove fromDate and toDate from here


  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };
  const fetchStores = async () => {
    const token = localStorage.getItem('token');
    try {

      const response = await fetch(`${apiurl}store`, {


        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch stores');
      }
      const data = await response.json();
      if (data.status === 'success' && Array.isArray(data.stores)) {
        const storeList = data.stores.map(store => ({
          id: store.storeId,
          storeName: store.storeName,
        }));
        setStores(storeList);
      } else {
        console.error('Unexpected data format:', data);
        setStores([]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
      setStores([]);
    }
  };

  const fetchProducts = async () => {
    const token = localStorage.getItem('token');
    try {

      const response = await fetch(`${apiurl}product/list`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      if (data.status === 'success' && Array.isArray(data.products)) {
        const productList = data.products.map(product => ({
          id: product.productId,
          productName: product.productName,
        }));
        setProductsData(productList);
      } else {
        console.error('Unexpected data format:', data);
        setProductsData([]);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setProductsData([]);
    }
  };

  useEffect(() => {
    fetchStores();
    fetchProducts();
  }, []);

  return (
    <div className={styles.container}>
    <div  className={styles.mainContainer} >
      {loading && <LoadingOverlay />}
      <Grid container spacing={2}>
          <Grid item>
          <TextField
            name="fromDate"
            label="From Date"
            type="date"
            value={fromDate}
            onChange={handleFromDateChange}
            sx={{
              ...commonTextFieldStyles,
              width: '150px',
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            name="toDate"
            label="To Date"
            type="date"
            value={toDate}
            onChange={handleToDateChange}
            sx={{
              ...commonTextFieldStyles,
              width: '150px',
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            name="search"
            label="Search by Product Name"
            value={search}
            onChange={handleQueryChange}
            InputProps={{
              className: styles.invoiceinput,
            }}
            InputLabelProps={{
              className: styles.invoicelabel,
              shrink: true
            }}
          />
        </Grid>

        <FormControl size='small' sx={{ margin: 1, minWidth: 200, height: 40, marginTop: 2 }}>
            <Autocomplete
              options={stores}
              getOptionLabel={(option) => option.storeName}
              value={stores.find(store => store.id === storeIds) || null}
              onChange={handleStoreChange}
              onInputChange={handleStoreInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Store"
                  variant="outlined"
                  size="small"
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.storeName}
                </li>
              )}
            />
          </FormControl>


        <Grid item>
          <Button
            sx={{
                backgroundColor: '#009999',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#01dcb8',
                },
              }}
            variant="contained"
            onClick={handleSearch}
            startIcon={<MdSearch />}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid container>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 120, maxHeight:5, marginBottom:5,marginTop:3 }}>
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              label="Rows per page"
            >
              {[10, 25, 50, 100].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}  
                </MenuItem>
              ))}
            </Select>
          </FormControl>
      </Grid>
      <div className={styles.tablecon}>
        <TableContainer component={Paper}>
          <Table className={styles.storetable}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Sr No.</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Product Name</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Image</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Pieces</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Rate</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Amount</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Stock</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>StoreName</TableCell>
                {/* <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '13px', backgroundColor: '#009999', border: '1px solid #333', color: '#fff' }}>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {inventoryList.map((product, index) => (
                <TableRow key={product._id} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f0f0f0' }}>
                  <TableCell style={tableCellStyle}>
                            {(Number(index || 0) + 1 + (Number(currentPage || 1) - 1) * Number(itemsPerPage || 10))
                                .toString()
                                .padStart(4, '0')}
                            </TableCell>
                  <TableCell style={tableCellStyle}>{product.productName}</TableCell>
                  <TableCell style={tableCellStyle}>
                    <img
                      src={product.image}
                      alt={product.productName}
                      style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                      onClick={() => handleImageClick(product.image)}
                    />
                  </TableCell>
                  <TableCell style={tableCellStyle}>{product.pieces}</TableCell>
                  <TableCell style={tableCellStyle}>{product.rate}</TableCell>
                  <TableCell style={tableCellStyle}>{product.amount}</TableCell>
                  <TableCell style={{
                                  ...tableCellStyle,
                                  fontWeight: 'bold',
                                  color: product?.stock === 'IN' ? 'green' : product?.stock === 'OUT' ? 'red' : 'black',
                              }}
                          >
                              {product?.stock || 'N/A'}
                          </TableCell>
                  <TableCell style={tableCellStyle}>{product.storeName}</TableCell>
                  {/* <TableCell style={tableCellStyle}>
                    <IconButton onClick={handleEdit}> <ModeEditOutline className={styles.editIcon}/> </IconButton>
                    <IconButton onClick={handleDelete}> <DeleteIcon className={styles.editDelete}/> </IconButton>
                    </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#009999',
                border: '1px solid #ddd',
                margin: '0 4px',
                borderRadius: '4px',
              },
              '& .Mui-selected': {
                backgroundColor: '#009999',
                color: 'white',
              },
            }}
          />
        </Box>
      </div>
        {/* ======================================image inlarge=========================================================================================== */}

        <Dialog open={enlargedImage !== null} onClose={handleCloseEnlargedImage}>
          <DialogContent>
            <img src={enlargedImage} alt="Enlarged product" style={{ width: '100%', height: 'auto' }} />
          </DialogContent>
        </Dialog>
    </div>
    </div>
  );
};

export default TransferHistory;